import React, {FC, PropsWithChildren, useEffect, useRef, useState} from 'react';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '../redux/store';
import {
    ENGLISH,
    ENGLISH_APP,
    ENGLISH_PAGE_ADDRESS,
    LOGIN_STATE_KEY,
    PERSIAN,
    PERSIAN_PAGE_ADDRESS,
    THEME_DARK,
    THEME_LIGHT,
    THEME_STATE_KEY
} from '../services/CONSTANTS';
import {
    Alert,
    Button,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Snackbar,
    Stack,
    SwipeableDrawer,
    Tab
} from '@mui/material';
import useLocalStorageState from '../utils/useLocalStorage';
import MenuIcon from '@mui/icons-material/Menu';
import {
    LOGO_ICON,
    LOGO_TEXT_ADDRESS,
    LOGO_TEXT_ADDRESS_EN,
    PAGE_CLUB_KEY,
    PAGE_GAME_KEY,
    PAGE_HOME_KEY,
    PAGE_SEARCH_KEY,
    PAGE_STORE_KEY,
    PAGE_TOOLS_KEY,
    PAGE_VIDEO_KEY,
    SEARCH_ICON_ADDRESS
} from '../../page/Main/CONSTANTS';
import i18n from 'i18next';
import {TabContext, TabList} from '@mui/lab';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import {
    CAFE_DOWNLOAD_IMAGE,
    CAFE_TOOLBOX_URL,
    DIRECT_CAFE_TOOLBOX_URL,
    DIRECT_DOWNLOAD_EN_IMAGE,
    DIRECT_DOWNLOAD_IMAGE,
    DIRECT_PLAY_TOOLBOX_URL,
    MYKET_DOWNLOAD_IMAGE,
    MYKET_TOOLBOX_URL,
    PLAY_DOWNLOAD_IMAGE,
    PLAY_TOOLBOX_URL
} from '../../page/profile/CONSTANTS';
import {ColumnComponent} from './base/column.component';
import {BottomNavigationCustom} from './ui/bottomNav/bottomNavigationCustom';
import {BottomItems} from './ui/bottomNav/CONSTANTS';
import {Profile} from '../../page/profile';
import {getLastTab, saveAppLanguage, saveLastTab} from '../services/localStorage.service';
import {MenuBuilderDetailModel, MenuBuilderWidgetModel} from '../../page/Main/main.model';
import {MENU_BUILDER_ACTION} from '../../page/Main/redux/mainAction';
import {LOGIN_ACTION} from '../../page/profile/components/Login/redux/loginAction';
import {LOGIN_STATE, LOGOUT_STATE} from '../../page/profile/components/Login/CONSTANTS';
import {useTranslation} from 'react-i18next';
import {MenuDatabaseHelper} from '../../page/Home/components/MenuBuilder/database/menu.database.helper';
import {useRouter} from 'next/router';
import {RowComponent} from './base/row.component';
import {toPersianNumber} from '../utils/number.utils';
import {BlueButton} from './ui/blue.button';
import * as rdd from 'react-device-detect';
import IosShareIcon from '@mui/icons-material/IosShare';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import Image from 'next/image';
import {Vazirmatn} from "next/font/google";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {Check} from "@mui/icons-material";
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import {SearchShortcutComponent} from "../../page/Home/components/MenuBuilder/search/searchShortcut.component";

const vazirmatn = Vazirmatn({subsets: ['arabic'], variable: '--vazirmatn'});

const ContainerLayout: FC<PropsWithChildren> = (props) => {
    const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
    const messageState = useAppSelector((state) => state.mReducer);

    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const [snackbarSort, setSnackbarSort] = React.useState<any>('success');
    const [isDark, setDark] = useLocalStorageState<boolean>(THEME_STATE_KEY, false);
    const [isThemeDark, setThemeDark] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [searchWidget, setSearchWidget] = useState<MenuBuilderWidgetModel|null>(null);
    const [isLogin, setLogin] = useLocalStorageState<boolean>(LOGIN_STATE_KEY, false);
    const [anchorLanguage, setAnchorLanguage] = React.useState<null | HTMLElement>(null);
    const openLanguageMenu = Boolean(anchorLanguage);
    const {t} = useTranslation();
    const [bottomNavValue, setBottomNavValue] = useState<string>();
    let dispatch = useDispatch<AppDispatch>();
    const router = useRouter();
    const reserveLanguage = useRef<String>('');
    const databaseHelper = new MenuDatabaseHelper();
    const [installPopUpOpen, setInstallPopUpOpen] = useState(false);
    const [isIosPopUp, setIosPopUp] = useState(false);
    const [hasToolbar, setHasToolbar] = useState(false);
    const [inMainRoute, setMainRoute] = useState(true);

    useEffect(() => {
        dispatch({
            type: MENU_BUILDER_ACTION,
            state: getLastTab()
        });
        setBottomNavValue(getLastTab());
        reserveLanguage.current = i18n.language ?? '';
        if (rdd.isMobile) {
            if (router.pathname == '/' && !isPWAInstall()) {
                if (rdd.isIOS) {
                    setIosPopUp(true);
                }
                setInstallPopUpOpen(true);
            }
        }
    }, [i18n.language]);

    useEffect(() => {
        if (isDark != isThemeDark) {
            setThemeDark(isDark);
        }
    }, [isDark]);

    useEffect(() => {
        if (router.isReady) {
            if (router.pathname.includes('/shortcutList')) {
                setHasToolbar(false);
            } else {
                setHasToolbar(true);
            }
        }
        if (router.pathname === "/") {
            setMainRoute(true);
        } else {
            setSearchWidget(null);
            setMainRoute(false);
        }
    }, [router.query]);

    function isPWAInstall(): boolean {
        if (typeof window !== 'undefined') {
            return window.matchMedia('(display-mode: standalone)').matches;
        } else {
            return false;
        }
    }

    const handleClickLanguage = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorLanguage(event.currentTarget);
    };

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorLanguage(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setTimeout(() => {
            setAnchorLanguage(null);
        }, 1500)
    };


    const handleCloseLanguage = () => {
        setAnchorLanguage(null);
    };

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    useEffect(() => {
        if (messageState.message) {
            setSnackbarSort(messageState.sort);
            setSnackbarMessage(messageState.message);
            setOpenSnackbar(true);
        }
        //eslint-disable-next-line
    }, [messageState]);

    const handleChange = (event: React.ChangeEvent<any>, newValue: any) => {
        changeTabProcess(newValue);
    };

    function changeTabProcess(newValue: string) {
        saveLastTab(newValue);
        dispatch({
            type: MENU_BUILDER_ACTION,
            state: newValue
        });
        setBottomNavValue(newValue);
        if (router.pathname !== '/') {
            router.replace('/');
        }
    }

    const handleChangeBottomNav = (newValue: string) => {
        changeTabProcess(newValue);
    };

    function onSearchClick() {
        return () => {
            findSearchPage();
        };
    }

    function findSearchPage() {
        databaseHelper.getMenuPage(PAGE_SEARCH_KEY).then((page) => {
            if (page && page.length > 0) {
                const mainPage: MenuBuilderDetailModel = {
                    id: page[0].id,
                    title: page[0].title,
                    unique_name: page[0].unique_name,
                    group: page[0].group,
                    widgets: [],
                    sub_pages: []
                };
                databaseHelper.getMenuItems(mainPage, page[0].widgetIds).then((widgets) => {
                    if (page) {
                        setSearchWidget(widgets[0])
                    }
                });
            }
        });
    }

    function showLoginPage(isLoginState: boolean) {
        if (isLoginState) {
            return () => {
                dispatch({
                    type: LOGIN_ACTION,
                    openLoginPage: true,
                    state: LOGIN_STATE
                });
            };
        } else {
            return () => {
                dispatch({
                    type: LOGIN_ACTION,
                    openLoginPage: true,
                    state: LOGOUT_STATE
                });
            };
        }
    }

    const setDarkThemeClick = () => {
        setDark(true);
    };

    const setLightThemeClick = () => {
        setDark(false);
    };

    const setPersianLanguageClick = () => {
        saveAppLanguage(PERSIAN);
        i18n.changeLanguage(PERSIAN);
        if (typeof window !== 'undefined') window.open(PERSIAN_PAGE_ADDRESS, '_self');
    };

    const setEnglishLanguageClick = () => {
        if (typeof window !== 'undefined') window.open(ENGLISH_PAGE_ADDRESS, '_self');
    };


    return (
        <ColumnComponent className={`App ${isThemeDark ? THEME_DARK : THEME_LIGHT}`}>
            <Container maxWidth={'lg'} className={'w-full flex relative min-h-screen'} disableGutters={true}>
                {searchWidget?
                    <SearchShortcutComponent widget={searchWidget} onBackPress={()=>setSearchWidget(null)} />
                    :
                    <ColumnComponent>
                        {hasToolbar ? (
                            <RowComponent
                                className={`toolbarTopFrame fixed top-0 z-30 totalCenter left-0 right-0 ${inMainRoute ? "flex" : "hidden md:flex"}`}>
                                <Container maxWidth={'lg'} sx={{display: "flex"}} className={`w-full totalCenter flex-row`}
                                           disableGutters={true}>
                                    <div className="mt-1 mx-1 rounded-full pointer block lg:hidden "
                                         onClick={() => setDrawerOpen(true)}>
                                        <MenuIcon fontSize={'medium'}/>
                                    </div>
                                    <div className="mt-1 px-4 rounded-full pointer hidden lg:flex flex flex-row totalCenter"
                                         onClick={() => changeTabProcess(PAGE_HOME_KEY)}>
                                        <Image fill={true} sizes={'100%'} alt={''} src={LOGO_ICON}
                                               className={'!w-8 !h-8 !relative'}/>
                                        <Image
                                            fill={true}
                                            sizes={'100%'}
                                            alt=""
                                            className="logoImageText !relative"
                                            src={i18n.language && i18n.language == ENGLISH ? LOGO_TEXT_ADDRESS_EN : LOGO_TEXT_ADDRESS}
                                        />
                                    </div>
                                    {i18n.language == ENGLISH ? null : (
                                        <div className="flex hidden md:block sm:w-2/3 md:w-1/2 lg:w-2/3 mx-2">
                                            <TabContext value={bottomNavValue ?? PAGE_HOME_KEY}>
                                                <TabList variant={'scrollable'} orientation={'horizontal'}
                                                         onChange={handleChange}>
                                                    <Tab className="text-bold text-base font" label={t('home')}
                                                         value={PAGE_HOME_KEY}/>
                                                    <Tab className="text-bold text-base font" label={t('tools')}
                                                         value={PAGE_TOOLS_KEY}/>
                                                    <Tab className="text-bold text-base font" label={t('store')}
                                                         value={PAGE_STORE_KEY}/>
                                                    <Tab className="text-bold text-base font" label={t('videoPlus')}
                                                         value={PAGE_VIDEO_KEY}/>
                                                    <Tab className="text-bold text-base font" label={t('game')}
                                                         value={PAGE_GAME_KEY}/>
                                                    <Tab className="text-bold text-base font" label={t('club')}
                                                         value={PAGE_CLUB_KEY}/>
                                                </TabList>
                                            </TabContext>
                                        </div>
                                    )}
                                    <Stack direction={"row"} spacing={.5} sx={{display: {xs: "none", md: "flex"}}}
                                           className="mt-1 mx-3 totalCenter">
                                        <IconButton className={'!w-10 !h-10'}>
                                            {isDark ? <LightModeOutlinedIcon fontSize={'medium'} onClick={setLightThemeClick}
                                                                             className={'mx-2 pointer mText opacity-50'}/> :
                                                <DarkModeOutlinedIcon fontSize={'medium'} onClick={setDarkThemeClick}
                                                                      className={'mx-2 pointer mText opacity-50'}/>}
                                        </IconButton>
                                        {(`${process.env.NEXT_PUBLIC_NODE_ENV}`.trim() !== ENGLISH_APP) ?
                                            <AccountCircleOutlinedIcon
                                                fontSize={'medium'}
                                                className={'mx-2 pointer mText opacity-50'}
                                                onClick={showLoginPage(isLogin)}
                                            /> : null}
                                    </Stack>
                                    <div
                                        className="flex flex-row grow w-full md:width-auto p-1 mt-1 mx-1 toolbarSearchFrame items-center pointer"
                                        onClick={onSearchClick()}
                                    >
                                        <Image
                                            alt=""
                                            fill={true}
                                            sizes={'100%'}
                                            className="searchIcon p-1 ms-2 !relative"
                                            src={SEARCH_ICON_ADDRESS}
                                        />
                                        <div className="toolbarItems text-base p-1">{t('searchIn')}</div>
                                    </div>
                                    <Button
                                        aria-haspopup="true"
                                        variant="outlined"
                                        disableElevation
                                        size={"small"}
                                        id={"language"}
                                        sx={{display: {xs: "none", md: "flex"}}}
                                        className={"totalCenter px-2 !mx-1 cursor-none"}
                                        onMouseEnter={handlePopoverOpen}
                                        onMouseLeave={handlePopoverClose}
                                        onClick={handleClickLanguage}
                                        endIcon={<KeyboardArrowDownIcon/>}
                                    >
                                        <div
                                            className={"px-1"}>{(`${process.env.NEXT_PUBLIC_NODE_ENV}`.trim() === ENGLISH_APP) ? t('profile.englishLanguage') : t('profile.persianLanguage')}</div>
                                    </Button>
                                    <Menu
                                        anchorEl={anchorLanguage}
                                        id="language-menu"
                                        open={openLanguageMenu}
                                        onClose={handleCloseLanguage}
                                        onClick={handleCloseLanguage}
                                        disableAutoFocusItem
                                        disableRestoreFocus
                                        PaperProps={{
                                            elevation: 0,
                                            sx: {
                                                overflow: 'visible',
                                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                direction: "ltr",
                                                mt: 1.5,
                                                '& .MuiAvatar-root': {
                                                    width: 32,
                                                    height: 32,
                                                    ml: -0.5,
                                                    mr: 1
                                                },
                                                '&:before': {
                                                    content: '""',
                                                    display: 'block',
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 14,
                                                    width: 10,
                                                    height: 10,
                                                    bgcolor: 'background.paper',
                                                    transform: 'translateY(-50%) rotate(45deg)',
                                                    zIndex: 0
                                                }
                                            }
                                        }}
                                    >
                                        <MenuItem className={"pointer"}>
                                            <RowComponent className={'p-1 pointer'}
                                                          onClick={setPersianLanguageClick}>
                                                <div
                                                    className="grow px-2 w-full text-start">{t('profile.persianLanguage')}</div>
                                                {i18n.language == PERSIAN ?
                                                    <Check fontSize={'medium'} className="mTextBlue"/> : null}
                                            </RowComponent>
                                        </MenuItem>
                                        <MenuItem className={"pointer"}>
                                            <RowComponent className={'p-1 pointer'}
                                                          onClick={setEnglishLanguageClick}>
                                                <div
                                                    className="grow px-2 w-full text-start">{t('profile.englishLanguage')}</div>
                                                {i18n.language == ENGLISH ?
                                                    <Check fontSize={'medium'} className="mTextBlue"/> : null}
                                            </RowComponent>
                                        </MenuItem>
                                    </Menu>
                                </Container>
                            </RowComponent>

                        ) : null}
                        <ColumnComponent className={`${hasToolbar ? (inMainRoute ? "mt-16" : "mt-0 md:mt-16") : "mt-0"}`}>
                            {props.children}
                        </ColumnComponent>
                        {i18n.language == PERSIAN && hasToolbar ? (
                            <div
                                className={`fixed w-full bottom-0 ${inMainRoute ? "flex" : "hidden"} flex-row md:hidden totalCenter bottomNavFrame`}>
                                <BottomNavigationCustom
                                    items={BottomItems}
                                    activeColor={'#ff0'}
                                    deActiveColor={'#363636'}
                                    valueKey={bottomNavValue ?? PAGE_HOME_KEY}
                                    positionChange={handleChangeBottomNav}
                                />
                            </div>
                        ) : null}
                    </ColumnComponent>
                }
            </Container>
            <SwipeableDrawer
                anchor={i18n.language == ENGLISH ? 'left' : 'right'}
                open={drawerOpen}
                variant={'temporary'}
                disableSwipeToOpen={true}
                PaperProps={{
                    square: false,
                    elevation: 0,
                    style: {
                        backgroundColor: 'transparent',
                        left: i18n.language == ENGLISH ? 0 : 'unset',
                        right: i18n.language == ENGLISH ? 'unset' : 0,
                        width: '16rem'
                    }
                }}
                onOpen={() => setDrawerOpen(true)}
                onClose={() => setDrawerOpen(!drawerOpen)}
            >
                <ColumnComponent className={`light h-full ${vazirmatn.className}`}>
                    <div className={`flex-col flex w-full h-full bg-white`}>
                        <Profile closeDrawer={() => setDrawerOpen(false)}/>
                    </div>
                </ColumnComponent>
            </SwipeableDrawer>
            <SwipeableDrawer
                anchor={'bottom'}
                open={installPopUpOpen}
                disableSwipeToOpen={true}
                PaperProps={{square: true, elevation: 0, style: {backgroundColor: '#fff'}}}
                onOpen={() => setInstallPopUpOpen(true)}
                onClose={() => setInstallPopUpOpen(!installPopUpOpen)}
            >
                <Container maxWidth={'md'} disableGutters={true}
                           className={`w-screen min-h-screen background App light ${vazirmatn.className}`}>
                    {isIosPopUp ? (
                        <ColumnComponent className={'items-center relative h-full'}>
                            <div className="mt-20 px-4 rounded-full pointer flex flex-row totalCenter">
                                <Image fill={true} sizes={'100%'} alt={''} src={LOGO_ICON}
                                       className={'!w-16 !h-16 !relative'}/>
                                <Image
                                    fill={true}
                                    sizes={'100%'}
                                    alt=""
                                    className="logoImageText !relative"
                                    src={i18n.language && i18n.language == ENGLISH ? LOGO_TEXT_ADDRESS_EN : LOGO_TEXT_ADDRESS}
                                />
                            </div>
                            <div
                                className={'px-8  text-lg text-center font-bold mt-8'}>{t('profile.addToolboxToMainPageIos')}</div>
                            <div
                                className={'px-4 text-base text-center mt-8'}>{t('profile.addToolboxToMainPageIosDesc')}</div>
                            <RowComponent className={'totalCenter px-8  mt-8'}>
                                <div
                                    className={'text-sm w-full'}>{toPersianNumber(t('profile.addToolboxToMainPageIosGuide1'))}</div>
                                <IosShareIcon fontSize={'medium'} className={'mx-2 !w-8'}/>
                            </RowComponent>
                            <RowComponent className={'totalCenter px-8  mt-4'}>
                                <div
                                    className={'text-sm w-full'}>{toPersianNumber(t('profile.addToolboxToMainPageIosGuide2'))}</div>
                                <AddBoxOutlinedIcon fontSize={'medium'} className={'mx-2 !w-8'}/>
                            </RowComponent>
                            <RowComponent className={'totalCenter px-8  mt-4'}>
                                <div
                                    className={'text-sm w-full'}>{toPersianNumber(t('profile.addToolboxToMainPageIosGuide3'))}</div>
                                <div className={'!w-12'}>Add</div>
                            </RowComponent>
                            <BlueButton
                                width={'w-2/3'}
                                className={'absolute bottom-20'}
                                text={t('profile.notNow')}
                                onClick={() => setInstallPopUpOpen(false)}
                            />
                        </ColumnComponent>
                    ) : (
                        <ColumnComponent className={'items-center relative h-full'}>
                            <div className="mt-20 px-4 rounded-full pointer flex flex-row totalCenter">
                                <Image fill={true} sizes={'100%'} alt={''} src={LOGO_ICON}
                                       className={'!w-16 !h-16 !relative'}/>
                                <Image
                                    fill={true}
                                    sizes={'100%'}
                                    alt=""
                                    className="logoImageText !relative"
                                    src={i18n.language && i18n.language == ENGLISH ? LOGO_TEXT_ADDRESS_EN : LOGO_TEXT_ADDRESS}
                                />
                            </div>
                            <div className={'px-8 text-lg text-center font-bold mt-4'}>
                                <span>{t('profile.addToolboxToYourApplication')}</span><span role="img"
                                                                                             aria-label="dog">🌹</span>
                            </div>
                            {(`${process.env.NEXT_PUBLIC_NODE_ENV}`.trim() == ENGLISH_APP) ? null :
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className={"w-full flex flex-row totalCenter"}
                                    href={CAFE_TOOLBOX_URL}>
                                    <Image
                                        fill={true}
                                        sizes={'100%'}
                                        alt={''}
                                        className={'mx-2 rounded-md pointer !h-12 mt-8 px-8 !relative'}
                                        src={CAFE_DOWNLOAD_IMAGE}
                                    />
                                </a>}
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className={"w-full flex flex-row totalCenter"}
                                href={PLAY_TOOLBOX_URL}>
                                <Image
                                    fill={true}
                                    sizes={'100%'}
                                    alt={''}
                                    className={'mx-2 rounded-md pointer !h-12 mt-4 px-8 !relative'}
                                    src={PLAY_DOWNLOAD_IMAGE}
                                />
                            </a>
                            {(`${process.env.NEXT_PUBLIC_NODE_ENV}`.trim() == ENGLISH_APP) ? null :
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    className={"w-full flex flex-row totalCenter"}
                                    href={MYKET_TOOLBOX_URL}>
                                    <Image
                                        fill={true}
                                        sizes={'100%'}
                                        alt={''}
                                        className={'mx-2 rounded-md pointer !h-12 mt-4 px-8 !relative'}
                                        src={MYKET_DOWNLOAD_IMAGE}
                                    />
                                </a>}
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className={"w-full flex flex-row totalCenter"}
                                href={i18n.language == ENGLISH ? DIRECT_PLAY_TOOLBOX_URL : DIRECT_CAFE_TOOLBOX_URL}>
                                <Image
                                    fill={true}
                                    sizes={'100%'}
                                    alt={''}
                                    className={'mx-2 rounded-md pointer !h-12 mt-4 px-8 !relative'}
                                    src={(`${process.env.NEXT_PUBLIC_NODE_ENV}`.trim() == ENGLISH_APP) ? DIRECT_DOWNLOAD_EN_IMAGE : DIRECT_DOWNLOAD_IMAGE}
                                />
                            </a>
                            <BlueButton
                                width={'w-2/3'}
                                className={'absolute bottom-20'}
                                text={t('profile.notNow')}
                                onClick={() => setInstallPopUpOpen(false)}
                            />
                        </ColumnComponent>
                    )}
                </Container>
            </SwipeableDrawer>
            <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={snackbarSort} sx={{width: '100%'}}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </ColumnComponent>
    );
};
export default ContainerLayout;
