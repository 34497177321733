import {AnyAction} from 'redux';
import {MESSAGE_ACTION} from './messageActions';

interface MessageState {
  sort: string;
  message: string;
}

const initialMessageState: MessageState = {
  sort: 'success',
  message: ''
};

export function messageReducer(state = initialMessageState, action: AnyAction) {
  switch (action.type) {
    case MESSAGE_ACTION:
      return {
        sort: action.sort,
        message: action.message
      };
    default:
      return state;
  }
}
