import React, {FC} from 'react';
import ShortcutComponent from '../shortcut/shortcut.component';
import {RowComponent} from '../../../../../core/components/base/row.component';
import {WidgetProps} from './component/widgetTitleBar';
import {ColumnComponent} from '../../../../../core/components/base/column.component';

export const SearchComponent: FC<WidgetProps> = (props) => {
  return (
    <ColumnComponent className="totalCenter">
      <RowComponent className="flex-wrap px-2">
        {props.widget.shortcuts?.map((item, index) => (
          <div key={index} className="w-1/4 md:w-auto p-2">
            <ShortcutComponent shortcut={item} />
          </div>
        ))}
      </RowComponent>
    </ColumnComponent>
  );
};
