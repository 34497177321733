import {MenuBuilderShortcutModel, MenuBuilderWidgetModel} from '../../../../Main/main.model';
import i18n from '../../../../../core/utils/i18n';

export const checkShortcutListIncludeSearchString = (
  array: MenuBuilderWidgetModel,
  searchText: string
): MenuBuilderWidgetModel => {
  if (array) {
    let widget = {} as MenuBuilderWidgetModel;
    let shortcuts: MenuBuilderShortcutModel[] = [];
    let shNameArray: MenuBuilderShortcutModel[] = [];
    array.shortcuts.forEach((item) => {
      if (item.title) {
        shNameArray.push(item);
      } else {
        item.title = {
          fa: i18n.t(`shName.${item.unique_name}`),
          en: i18n.t(`shName.${item.unique_name}`)
        };
        shNameArray.push(item);
      }
    });
    shNameArray.forEach((shortcut) => {
      if (shortcut?.title?.fa?.toLowerCase().includes(searchText.toLowerCase())) {
        shortcuts.push(shortcut);
      } else if (shortcut?.title?.en?.toLowerCase().includes(searchText.toLowerCase())) {
        shortcuts.push(shortcut);
      }
      return '';
    });
    widget.shortcuts = shortcuts;
    return widget;
  }
  return array;
};
