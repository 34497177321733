import {
    AYE_BOOKMARK_STATE_KEY,
    BRT_CITY,
    GHARI,
    LANGUAGE,
    LAST_TAB,
    LOGIN_ACCESS_CODE_KEY,
    LOGIN_REFRESH_CODE_KEY,
    LOGIN_STATE_KEY,
    PERSIAN,
    PHONE_NUMBER,
    QURAN_FONT_SIZE,
    SORE_BOOKMARK_STATE_KEY,
    SUBWAY_CITY,
    THEME,
    THEME_LIGHT,
    TRANSLATE_ENABLE,
    USER_ID,
    VOICE_TRANSLATE_ENABLE,
    WBW_ENABLE
} from './CONSTANTS';
import {PAGE_HOME_KEY} from '../../page/Main/CONSTANTS';
import * as CryptoJS from 'crypto-js';

export function saveAppVersion(language: string, version: string) {
  localStorage.setItem(language, version);
}

export function saveAppLanguage(language: string) {
  localStorage.setItem(LANGUAGE, language);
}

export function saveQuranFont(fontSize: string) {
  localStorage.setItem(QURAN_FONT_SIZE, fontSize);
}

export function saveLastTab(tab: string) {
  localStorage.setItem(LAST_TAB, tab);
}

export function saveSubwayCity(city: string) {
  localStorage.setItem(SUBWAY_CITY, city);
}

export function saveBRTCity(city: string) {
  localStorage.setItem(BRT_CITY, city);
}

export function getSubwayCity(): string {
  return localStorage.getItem(SUBWAY_CITY) ?? '';
}

export function getBRTCity(): string {
  return localStorage.getItem(BRT_CITY) ?? '';
}

export function getAppVersion(language: string): string {
  return localStorage.getItem(language) ?? '0';
}

export function getLastTab(): string {
  return localStorage.getItem(LAST_TAB) || PAGE_HOME_KEY;
}

export function getQuranFontSize(): string {
  return localStorage.getItem(QURAN_FONT_SIZE) || '1';
}

export function getAppLanguage(): string {
  try {
    return localStorage.getItem(LANGUAGE) ?? PERSIAN;
  } catch (e) {
    return PERSIAN;
  }
}

export function getAppPackage(): string {
  return process.env.NEXT_PUBLIC_PACKAGE ? process.env.NEXT_PUBLIC_PACKAGE : '';
}

export function saveAppTheme(theme: string) {
  localStorage.setItem(THEME, theme);
}

export function getAppTheme(): string {
  return localStorage.getItem(THEME) || THEME_LIGHT;
}

export function saveGhari(ghari: string) {
  localStorage.setItem(GHARI, ghari);
}

export function getGhari(): string {
  return localStorage.getItem(GHARI) || '0';
}

export function saveTranslateEnable(translateEnable: string) {
  localStorage.setItem(TRANSLATE_ENABLE, translateEnable);
}

export function saveVoiceTranslateEnable(translateEnable: string) {
    localStorage.setItem(VOICE_TRANSLATE_ENABLE, translateEnable);
}

export function saveWbwEnable(wbwEnable: string) {
  localStorage.setItem(WBW_ENABLE, wbwEnable);
}

export function isTranslateEnable(): string {
  return localStorage.getItem(TRANSLATE_ENABLE) || '1';
}

export function isVoiceTranslateEnable(): string {
    return localStorage.getItem(VOICE_TRANSLATE_ENABLE) || '0';
}

export function isWbwEnable(): string {
  return localStorage.getItem(WBW_ENABLE) || '0';
}

export function saveBookmarkParams(soreIndex: string, ayeIndex: string) {
  localStorage.setItem(SORE_BOOKMARK_STATE_KEY, soreIndex);
  localStorage.setItem(AYE_BOOKMARK_STATE_KEY, ayeIndex);
}

export function getSoreBookmark(): string {
  return localStorage.getItem(SORE_BOOKMARK_STATE_KEY) || '';
}

export function getAyeBookmark(): string {
  return localStorage.getItem(AYE_BOOKMARK_STATE_KEY) || '';
}

export function saveLoginUser(isLogin: boolean) {
  localStorage.setItem(LOGIN_STATE_KEY, isLogin ? 'true' : 'false');
}

export function isUserLogin(): boolean {
  return (localStorage.getItem(LOGIN_STATE_KEY) || 'false') == 'true';
}

export function saveLoginParams(token: string, refresh: string, number: string, userId: string) {
  const cipherToken = CryptoJS.AES.encrypt(`Bearer ${token}`, 'tokenKey').toString();
  const tokenValue = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(cipherToken));
  localStorage.setItem(LOGIN_ACCESS_CODE_KEY, tokenValue);
  if (refresh) {
    const cipherRefresh = CryptoJS.AES.encrypt(`Bearer ${refresh}`, 'refreshKey').toString();
    const refreshValue = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(cipherRefresh));
    localStorage.setItem(LOGIN_REFRESH_CODE_KEY, refreshValue);
  }
  const cipherPhoneNumber = CryptoJS.AES.encrypt(number, 'mobileKey').toString();
  const numberValue = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(cipherPhoneNumber));
  localStorage.setItem(PHONE_NUMBER, numberValue);
  const cipherUserId = CryptoJS.AES.encrypt(userId, 'userKey').toString();
  const userValue = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(cipherUserId));
  localStorage.setItem(USER_ID, userValue);
}

export function getLoginAccessToken(): string {
  const decData = CryptoJS.enc.Base64.parse(localStorage.getItem(LOGIN_ACCESS_CODE_KEY) || '').toString(
    CryptoJS.enc.Utf8
  );
  const bytes = CryptoJS.AES.decrypt(decData, 'tokenKey');
  return bytes.toString(CryptoJS.enc.Utf8);
}

export function getLoginRefreshToken(): string {
  const decData = CryptoJS.enc.Base64.parse(localStorage.getItem(LOGIN_REFRESH_CODE_KEY) || '').toString(
    CryptoJS.enc.Utf8
  );
  const bytes = CryptoJS.AES.decrypt(decData, 'refreshKey');
  return bytes.toString(CryptoJS.enc.Utf8);
}

export function getPhoneNumber(): string {
  const decData = CryptoJS.enc.Base64.parse(localStorage.getItem(PHONE_NUMBER) || '').toString(CryptoJS.enc.Utf8);
  const bytes = CryptoJS.AES.decrypt(decData, 'mobileKey');
  return bytes.toString(CryptoJS.enc.Utf8);
}

export function getUserId(): string {
  const decData = CryptoJS.enc.Base64.parse(localStorage.getItem(USER_ID) || '').toString(CryptoJS.enc.Utf8);
  const bytes = CryptoJS.AES.decrypt(decData, 'userKey');
  return bytes.toString(CryptoJS.enc.Utf8);
}
