// menuDB.ts
import Dexie, {Table} from 'dexie';
import {Shortcut} from './models/menudb.shortcut';
import {Widget} from './models/menudb.widget';
import {Page} from './models/menudb.page';
import {Favorite} from './models/menudb.favorite';

export class MySubClassedDexie extends Dexie {
  favorite!: Table<Favorite>;
  shortcut!: Table<Shortcut>;
  widget!: Table<Widget>;
  page!: Table<Page>;

  constructor() {
    super('menuTable');
    this.version(1).stores({
      shortcut:
        'id, titleFa,titleEn, unique_name, cover_url, icon_url, is_enabled, login_required, tooltip, tooltip_color, icon_color, url', // Primary key and indexed props
      favorite:
        'id, titleFa,titleEn, unique_name, cover_url, icon_url, is_enabled, login_required, tooltip, tooltip_color, icon_color, url, isSync, isDelete,date', // Primary key and indexed props
      widget: 'id, title, show_more_page, unique_name, category, *shortcutIds, blueprint_unique_name,*schema_data', // Primary key and indexed props
      page: 'id,lng, title, unique_name, group, *widgetIds, *subPageIds' // Primary key and indexed props
    });
  }
}

export const menuDb = new MySubClassedDexie();
