import {Observable} from 'rxjs';
import Api from '../../../core/services/Api';
import {getApiHeaderObjectWithToken} from '../../../core/services/config';
import {getAppPackage, getLoginAccessToken} from '../../../core/services/localStorage.service';
import {USER_SCORE_URL} from '../CONSTANTS';
import {ScoreModel} from '../model/score.model';
import i18n from 'i18next';

export class ScoreService {
  public getScore(page: number, pageSize: number): Observable<ScoreModel> {
    return Api.getWithHeader<ScoreModel>(
      USER_SCORE_URL,
      getApiHeaderObjectWithToken(getAppPackage(), i18n.language, getLoginAccessToken()),
      {
        page_size: pageSize,
        page: page
      }
    );
  }
}
