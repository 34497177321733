import React, {FC} from 'react';
import {LOADER_LARGE_SIZE, LOADER_MEDIUM_SIZE, LOADER_SMALL_SIZE} from './CONSTANTS';
import {ColumnComponent} from './base/column.component';
import {CircularProgress} from '@mui/material';

interface LoaderState {
  isFullScreen: boolean;
  size?: string;
}

export const Loader: FC<LoaderState> = (props) => {
  function getLoaderSize() {
    if (props.size) {
      switch (props.size) {
        case LOADER_LARGE_SIZE:
          return '1.5rem';
        case LOADER_MEDIUM_SIZE:
          return '1rem';
        case LOADER_SMALL_SIZE:
          return '.8rem';
        default:
          return '1rem';
      }
    } else {
      return '1rem';
    }
  }

  function getLoaderHeightSize() {
    if (props.size) {
      switch (props.size) {
        case LOADER_LARGE_SIZE:
          return '5rem';
        case LOADER_MEDIUM_SIZE:
          return '4rem';
        case LOADER_SMALL_SIZE:
          return '3rem';
        default:
          return '4rem';
      }
    } else {
      return '4rem';
    }
  }

  return (
    <ColumnComponent
      style={{ height: props.isFullScreen ? '100%' : getLoaderHeightSize() }}
      className={`hideScrollBar totalCenter w-full ${props.isFullScreen ? 'top30' : ''}`}
    >
      <CircularProgress color="secondary" sx={{ height: getLoaderSize(), color: '#9c4000' }} />
    </ColumnComponent>
  );
};
