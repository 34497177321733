import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import {ApiErrorHandler} from './errorHandler';

const initialization = (config: AxiosRequestConfig): AxiosInstance => {
  let axiosInstance: AxiosInstance;
  axiosInstance = axios.create(config);

  ApiErrorHandler(axiosInstance);

  return axiosInstance;
};

export default initialization;
