import {BottomNavItems} from './bottomNav.models';
import {
    PAGE_GAME_KEY,
    PAGE_HOME_KEY,
    PAGE_STORE_KEY,
    PAGE_TOOLS_KEY,
    PAGE_VIDEO_KEY
} from '../../../../page/Main/CONSTANTS';

const ACTIVE_HOME_IMAGE = '/image/svg_ic_home.svg';
const ACTIVE_HOME_IMAGE_ACTIVE = '/image/svg_ic_home_fill.svg';

const ACTIVE_TOOLS_IMAGE = '/image/svg_ic_tools.svg';
const ACTIVE_TOOLS_IMAGE_ACTIVE = '/image/svg_ic_tools_fill.svg';

const ACTIVE_SHOP_IMAGE = '/image/svg_ic_shop.svg';
const ACTIVE_SHOP_IMAGE_ACTIVE = '/image/svg_ic_shop_fill.svg';

const ACTIVE_GAME_IMAGE = '/image/svg_ic_game.svg';
const ACTIVE_GAME_IMAGE_ACTIVE = '/image/svg_ic_game_fill.svg';

const ACTIVE_VIDEO_IMAGE = '/image/svg_ic_video.svg';
const ACTIVE_VIDEO_IMAGE_ACTIVE = '/image/svg_ic_video_fill.svg';

const ACTIVE_ENTERTAINMENT_IMAGE = '/image/svg_ic_entertainment.svg';
const ACTIVE_ENTERTAINMENT_IMAGE_ACTIVE = '/image/svg_ic_entertainment_fill.svg';

const ACTIVE_CLUB_IMAGE = '/image/svg_ic_club.svg';
const ACTIVE_CLUB_IMAGE_ACTIVE = '/image/svg_ic_club_fill.svg';

export const BottomItems: BottomNavItems[] = [
  {
    image: ACTIVE_HOME_IMAGE,
    activeImage: ACTIVE_HOME_IMAGE_ACTIVE,
    valueKey: PAGE_HOME_KEY,
    text: 'home'
  },
  {
    image: ACTIVE_TOOLS_IMAGE,
    activeImage: ACTIVE_TOOLS_IMAGE_ACTIVE,
    valueKey: PAGE_TOOLS_KEY,
    text: 'tools'
  },
  {
    image: ACTIVE_SHOP_IMAGE,
    activeImage: ACTIVE_SHOP_IMAGE_ACTIVE,
    valueKey: PAGE_STORE_KEY,
    text: 'store'
  },
  {
    image: ACTIVE_VIDEO_IMAGE,
    activeImage: ACTIVE_VIDEO_IMAGE_ACTIVE,
    valueKey: PAGE_VIDEO_KEY,
    text: 'videoPlus'
  },
  {
    image: ACTIVE_GAME_IMAGE,
    activeImage: ACTIVE_GAME_IMAGE_ACTIVE,
    valueKey: PAGE_GAME_KEY,
    text: 'game'
  }
];
