import {AxiosInstance} from 'axios';
import {GET_VERIFICATION_REFRESH_TOKEN_URL} from './CONSTANTS';
import {
    getAppPackage,
    getLoginRefreshToken,
    getPhoneNumber,
    getUserId,
    saveLoginParams,
    saveLoginUser
} from './localStorage.service';

import {store} from '../redux/store';
import {MESSAGE_ACTION} from '../redux/reducers/messageActions';
import i18n from '../utils/i18n';
import {LOGIN_ACTION} from '../../page/profile/components/Login/redux/loginAction';
import {LOGIN_STATE} from '../../page/profile/components/Login/CONSTANTS';

function logoutUser() {
  saveLoginParams('', '', '', '');
  saveLoginUser(false);
  store.dispatch({
    type: LOGIN_ACTION,
    logoutUser: true,
    openLoginPage: true,
    state: LOGIN_STATE
  });
}

export function ApiErrorHandler(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      // console.log("on start Error")
      const originalRequest = error.config;
      // Prevent infinite loops
      if (error.response?.status === 401 && originalRequest.url === GET_VERIFICATION_REFRESH_TOKEN_URL) {
        return Promise.reject(error);
      }
      if (error.response?.status === 401) {
        const refreshToken = getLoginRefreshToken();

        if (refreshToken) {
          // console.log("refresh token")
          // console.log(refreshToken)
          const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));
          // exp date in token is expressed in seconds, while now() returns milliseconds:
          const now = Math.ceil(Date.now() / 1000);
          // console.log(`exp=${tokenParts.exp},now=${now}`)
          if (tokenParts.exp < now) {
            // console.log("exp date is in future")
            const headers = {
              'Content-Type': 'application/json',
              Authorization: refreshToken
            };
            return axiosInstance
              .post(GET_VERIFICATION_REFRESH_TOKEN_URL, { package_name: getAppPackage() }, { headers: headers })
              .then((response) => {
                if (response?.data && response?.data.data && response?.data.data.access) {
                  saveLoginParams(response.data.data.access, '', getPhoneNumber(), getUserId());
                  // console.log("refreshed token");
                  axiosInstance.defaults.headers.head['Authorization'] = 'Bearer ' + response.data.data.access;
                  originalRequest.headers['Authorization'] = 'Bearer ' + response.data.data.access;
                  return axiosInstance(originalRequest);
                } else {
                  logoutUser();
                }
              })
              .catch((err) => {
                logoutUser();
              });
          } else {
            logoutUser();
          }
        } else {
          logoutUser();
        }
      } else if (error.response?.status === 404) {
        let errorText = i18n.t('error.notFoundError');
        if (error.response?.data && error.response?.data.error && error.response?.data.error.description) {
          errorText = error.response.data.error.description;
        }
        store.dispatch({
          type: MESSAGE_ACTION,
          message: errorText,
          sort: 'error'
        });
      } else if (error.response?.status === 406) {
          let errorText = i18n.t('error.generalError');
          if (error.response?.data && error.response?.data.error && error.response?.data.error.description) {
              errorText = error.response.data.error.description;
          }
          store.dispatch({
              type: MESSAGE_ACTION,
              message: errorText,
              sort: 'error'
          });
      }else if (error.response?.status.toString().startsWith('50')) {
        let errorText = i18n.t('error.serverError');
        if (error.response?.data && error.response?.data.error && error.response?.data.error.description) {
          errorText = error.response.data.error.description;
        }
        store.dispatch({
          type: MESSAGE_ACTION,
          message: errorText,
          sort: 'error'
        });
      } else {
        let errorText = i18n.t('error.generalError');
        if (error.response?.data && error.response?.data.error && error.response?.data.error.description) {
          errorText = error.response.data.error.description;
          store.dispatch({
            type: MESSAGE_ACTION,
            message: errorText,
            sort: 'error'
          });
        }
      }

      // specific error handling done elsewhere
      return Promise.reject(error);
    }
  );
}
