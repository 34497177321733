export const THEME_DARK = 'dark';
export const THEME_LIGHT = 'light';
export const THEME = 'theme';
export const LANGUAGE = 'lng';
export const QURAN_FONT_SIZE = 'quranFontSize';
export const SUBWAY_CITY = 'subwayCity';
export const BRT_CITY = 'brtCity';
export const LAST_TAB = 'lastTab';
export const SH_TITLE = 'shTitle';
export const TOKEN = 't';
export const ARABIC = 'ar';
export const PERSIAN = 'fa';
export const ENGLISH = 'en';
export const GHARI = 'ghari';
export const TRANSLATE_ENABLE = 'translateEnable';
export const VOICE_TRANSLATE_ENABLE = 'voiceTranslateEnable';
export const WBW_ENABLE = 'wbwEnable';
export const ENGLISH_APP = "english";
export const PRODUCTION_APP = "production";

export const SORE_BOOKMARK_STATE_KEY = 'soreBookmarkState';
export const AYE_BOOKMARK_STATE_KEY = 'ayeBookmarkState';
export const LOGIN_STATE_KEY = 'loginState';
export const THEME_STATE_KEY = 'themeState';
export const SCORE_STATE_KEY = 'userScoreState';
export const LOGIN_ACCESS_CODE_KEY = 'loginAccessCode';
export const LOGIN_REFRESH_CODE_KEY = 'loginRefreshCode';
export const PHONE_NUMBER = 'phoneNumber';
export const USER_ID = 'userId';

export const ENGLISH_PAGE_ADDRESS = 'https://tlbxapp.com/';
export const PERSIAN_PAGE_ADDRESS = 'https://tlbx.ir/';

export const GET_VERIFICATION_REFRESH_TOKEN_URL = 'p/auth/v1/refresh';

export const PERSIAN_APP_PACKAGE = process.env.NEXT_PUBLIC_PACKAGE;
export const ENGLISH_APP_PACKAGE = process.env.NEXT_PUBLIC_PACKAGE;
