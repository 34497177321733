import React, {FC, useState} from 'react';
import {MenuBuilderWidgetModel} from '../../../../Main/main.model';
import {WIDGET_ACTION} from '../../../../Main/redux/mainAction';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../../../../core/redux/store';
import {useTranslation} from 'react-i18next';
import {checkShortcutListIncludeSearchString} from './search.shortcut.utils';
import {BackIconComponent} from '../../../../../core/components/backIcon.component';
import {ColumnComponent} from '../../../../../core/components/base/column.component';
import {RowComponent} from '../../../../../core/components/base/row.component';
import {SearchComponent} from '../widget/searchWidget.component';
import {useRouter} from 'next/router';
import {InputBase} from '@mui/material';

interface WidgetProps {
  widget: MenuBuilderWidgetModel;
  onBackPress:()=>void
}

export const SearchShortcutComponent: FC<WidgetProps> = (props) => {
  let dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const router = useRouter();
  const [searchText, setSearchText] = useState('');
  const [searchShortcutReserveList, setSearchShortcutReserveList] = useState<MenuBuilderWidgetModel>(props.widget);
  const [searchShortcutList, setSearchShortcutList] = useState<MenuBuilderWidgetModel>(props.widget);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.currentTarget.value);
    setSearchShortcutList(checkShortcutListIncludeSearchString(searchShortcutReserveList, event.currentTarget.value));
  };

  return (
    <div className="fullWidth background">
      <ColumnComponent className="totalCenter">
        <RowComponent className="sticky top-0 p-1 items-center backgroundCard z-10">
          <div className="grow p-1 mt-2 mx-3 toolbarSearchFrame flex flex-row items-center">
            <BackIconComponent
              onClick={() => {
                dispatch({
                  type: WIDGET_ACTION,
                  items: {},
                  hasSearch: false
                });
                props.onBackPress()
              }}
            />
            <InputBase
              autoFocus={true}
              value={searchText}
              className="grow input-group ms-1 me-3 mText"
              placeholder={t('searchTool')}
              onChange={handleChange}
            />
          </div>
        </RowComponent>
        <ColumnComponent className="mt-1 pt-1 px-1 background totalCenter">
          <SearchComponent widget={searchShortcutList} hasTitle={false} />
        </ColumnComponent>
      </ColumnComponent>
    </div>
  );
};
