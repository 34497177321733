import queryString from 'query-string';
import {
  HTTP_KEY,
  MAIN_APP_URL,
  WIDGET_HORIZONTAL_SQUARE,
  WIDGET_SHORTCUT_SQUARE_SWIPER,
  WIDGET_TOOLS
} from '../../CONSTANTS';
import {FILM_SERIAL_PATH, VIDEO_GARDI_PATH} from '../../../../../tools/video/CONSTANTS';
import {MenuBuilderShortcutModel, MenuBuilderWidgetModel} from '../../../../../Main/main.model';
import {AppDispatch} from '../../../../../../core/redux/store';
import {WIDGET_ACTION} from '../../../../../Main/redux/mainAction';
import {BRT_MAP, MENU_BUILDER, SHORTCUT_LIST, SUBWAY_MAP} from '../../../../../../core/navigation/CONSTANTS';
import {SHORTCUT_ACTION} from '../../widget/favorite/redux/shortcutAction';
import {NextRouter} from 'next/dist/shared/lib/router/router';

export function onShowMoreClick(widget: MenuBuilderWidgetModel, dispatch: AppDispatch, router: NextRouter) {
  if (widget.show_more_page?.includes('tlbx://')) {
    onShortcutClickFromUrl(widget.show_more_page, widget.title, router, dispatch);
  } else {
    removeShortcutClickState(dispatch);
    widget.schema_data.show_title = false;
    if (
      widget.blueprint_unique_name == WIDGET_HORIZONTAL_SQUARE ||
      widget.blueprint_unique_name == WIDGET_SHORTCUT_SQUARE_SWIPER
    ) {
      widget.blueprint_unique_name = WIDGET_HORIZONTAL_SQUARE;
    } else {
      widget.blueprint_unique_name = WIDGET_TOOLS;
    }
    dispatch({
      type: WIDGET_ACTION,
      items: widget,
      error: '',
      hasSearch: false
    });
    router.push({ pathname: SHORTCUT_LIST });
  }
}

function saveShortcutClickState(dispatch: AppDispatch, shortcut: MenuBuilderShortcutModel) {
  let hasSetting = false;
  switch (shortcut.unique_name) {
    case BRT_MAP.replace('/t/', ''):
    case SUBWAY_MAP.replace('/t/', ''):
      hasSetting = true;
      break;
  }
  shortcut.isDelete = shortcut?.isDelete ?? false;
  dispatch({
    type: SHORTCUT_ACTION,
    item: shortcut,
    hasSetting: hasSetting
  });
}

function removeShortcutClickState(dispatch: AppDispatch) {
  dispatch({
    type: SHORTCUT_ACTION,
    item: null,
    hasSetting: false
  });
}

export function onShortcutClick(
  shortcut: MenuBuilderShortcutModel,
  title: string,
  router: NextRouter,
  dispatch: AppDispatch
) {
  if (shortcut.url?.includes('tlbx://dynamicPage')) {
    removeShortcutClickState(dispatch);
    const qp = queryString.parseUrl(shortcut.url).query;
    if (qp && qp.group) {
      const group = qp.group.toString();
      const title = qp.title?.toString() ?? '';
      router.push({
        pathname: MENU_BUILDER.replace(':page', group),
        search: `shTitle=${title}`
      });
    }
  } else if (shortcut.url?.includes('tlbx://generalWebView')) {
    saveShortcutClickState(dispatch, shortcut);
    const qp = queryString.parseUrl(shortcut.url).query;
    if (qp && qp.url) {
      const path = qp.url.toString();
      if ((path.includes('tlbx.ir') || path.includes('tlbxapp.com'))&&(!path.includes('game.tlbx.ir')) && shortcut.unique_name) {
        let path: string = `/t/${shortcut.unique_name}`;
        router.push({
          pathname: path,
          search: `shTitle=${title}`
        });
      } else {
        router.push({
          pathname: '/t/generalWeb',
          query: { shTitle: title, title: title, url: path }
        });
      }
    }
  } else if (shortcut.url?.includes(HTTP_KEY)) {
    const qp = queryString.parseUrl(shortcut.url).query;
    if (qp && qp.url) {
      if (qp.url.toString().includes(MAIN_APP_URL)) {
        saveShortcutClickState(dispatch, shortcut);
        const path = qp.url.toString().split(MAIN_APP_URL)[1];
        router.push({
          pathname: path,
          search: `shTitle=${title}`
        });
      } else {
        if (typeof window !== 'undefined') window.open(qp.url.toString(), '_self');
      }
    } else {
      if (typeof window !== 'undefined') window.open(shortcut.url.toString(), '_self');
    }
  } else {
    saveShortcutClickState(dispatch, shortcut);
    let path: string = `/t/${shortcut.unique_name}`;
    if (shortcut.url?.includes('tlbx://media')) {
      if (path.includes('video_gardi')) {
        path = VIDEO_GARDI_PATH;
      } else if (path.includes('movie')) {
        path = FILM_SERIAL_PATH;
      }
      router.push({
        pathname: path,
        search: `${shortcut.url?.split('tlbx://media')[1]}&shTitle=${title}`
      });
    } else {
      router.push({
        pathname: path,
        search: `shTitle=${title}`
      });
    }
  }
}

export function getShortcutLinkParams(
    shortcut: MenuBuilderShortcutModel,
    title: string,
    dispatch: AppDispatch
): { pathname:string ,query:any , isOpenWindow:boolean}|undefined {
  if (shortcut.url?.includes('tlbx://dynamicPage')) {
    removeShortcutClickState(dispatch);
    const qp = queryString.parseUrl(shortcut.url).query;
    if (qp && qp.group) {
      const group = qp.group.toString();
      const title = qp.title?.toString() ?? '';
      return {
        pathname: MENU_BUILDER.replace(':page', group),
        query: {shTitle:title},
        isOpenWindow:false
      };
    }
  } else if (shortcut.url?.includes('tlbx://generalWebView')) {
    saveShortcutClickState(dispatch, shortcut);
    const qp = queryString.parseUrl(shortcut.url).query;
    if (qp && qp.url) {
      const path = qp.url.toString();
      if ((path.includes('tlbx.ir') || path.includes('tlbxapp.com'))&&(!path.includes('game.tlbx.ir')) && shortcut.unique_name) {
        let path: string = `/t/${shortcut.unique_name}`;
        return {
          pathname: path,
          query: {shTitle: title},isOpenWindow:false
        };
      } else {
        return {
          pathname: '/t/generalWeb',
          query: {shTitle: title, title: title, url: path},
          isOpenWindow:false
        };
      }
    }
  } else if (shortcut.url?.includes(HTTP_KEY)) {
    const qp = queryString.parseUrl(shortcut.url).query;
    if (qp && qp.url) {
      if (qp.url.toString().includes(MAIN_APP_URL)) {
        saveShortcutClickState(dispatch, shortcut);
        const path = qp.url.toString().split(MAIN_APP_URL)[1];
        return {
          pathname: path,
          query: {shTitle: title},
          isOpenWindow:false
        };
      } else {
        if (typeof window !== 'undefined') {
          return {
            pathname: qp.url.toString(),
            query: {},
            isOpenWindow: true
          };
          // window.open(qp.url.toString(), '_self');
        }
      }
    } else {
      if (typeof window !== 'undefined') {
        return {
          pathname: shortcut.url.toString(),
          query: {},
          isOpenWindow:true
        };
      }
    }
  } else {
    saveShortcutClickState(dispatch, shortcut);
    let path: string = `/t/${shortcut.unique_name}`;
    if (shortcut.url?.includes('tlbx://media')) {
      if (path.includes('video_gardi')) {
        path = VIDEO_GARDI_PATH;
      } else if (path.includes('movie')) {
        path = FILM_SERIAL_PATH;
      }
      return {
        pathname: path,
        query: `${shortcut.url?.split('tlbx://media?')[1]}&shTitle=${title}`,
        isOpenWindow:false
      };
    } else {
      return {
        pathname: path,
        query: {shTitle:title},
        isOpenWindow:false
      };
    }
  }
}

export function onShortcutClickFromUrl(url: string, title: string, router: NextRouter, dispatch: AppDispatch) {
  if (url?.includes('tlbx://dynamicPage')) {
    removeShortcutClickState(dispatch);
    const qp = queryString.parseUrl(url).query;
    if (qp && qp.group) {
      const group = qp.group.toString();
      const title = qp.title?.toString() ?? '';
      router.push({
        pathname: MENU_BUILDER.replace(':page', group),
        search: `shTitle=${title}`
      });
    }
  } else if (url?.includes('tlbx://generalWebView')) {
    const qp = queryString.parseUrl(url).query;
    if (qp && qp.url) {
      const path = qp.url.toString();
      router.push({
        pathname: '/t/generalWeb',
        query: { title: title, url: path }
      });
    }
  } else if (url?.includes(HTTP_KEY)) {
    const qp = queryString.parseUrl(url).query;
    if (qp && qp.url) {
      if (qp.url.toString().includes(MAIN_APP_URL)) {
        const path = qp.url.toString().split(MAIN_APP_URL)[1];
        router.push({
          pathname: path,
          search: `shTitle=${title}`
        });
      } else {
        if (typeof window !== 'undefined') window.open(qp.url.toString(), '_self');
      }
    } else {
      if (typeof window !== 'undefined') window.open(url.toString(), '_self');
    }
  } else if (url?.includes('tlbx://')) {
    let path: string = `/t/${url.split('tlbx://')[1]}`;
    if (url?.includes('tlbx://media')) {
      if (path.includes('isFilm=false')) {
        path = VIDEO_GARDI_PATH;
      } else if (path.includes('isFilm=true')) {
        path = FILM_SERIAL_PATH;
      }
      router.push({
        pathname: path,
        search: `${url?.split('tlbx://media')[1]}&shTitle=${title}`
      });
    } else {
      router.push({
        pathname: path,
        search: `shTitle=${title}`
      });
    }
  }
}
