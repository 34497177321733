import { AnyAction } from 'redux';
import { TestCategoryModel } from '../model/testCategory.model';
import { TEST_RESULT_ACTION } from './testResultAction';

export interface TestResultState {
  item: TestCategoryModel | null;
}

const initialTestResultState: TestResultState = {
  item: null
};

export function testResultReducer(state = initialTestResultState, action: AnyAction) {
  switch (action.type) {
    case TEST_RESULT_ACTION:
      return {
        item: action.item
      };
    default:
      return state;
  }
}
