export const HOME_ICON = '/image/ic_home.svg';
export const TOOLS_ICON = '/image/ic_tools.svg';
export const PAYMENT_ICON = '/image/ic_payment.svg';
export const CLUB_ICON = '/image/ic_club.svg';
export const PROFILE_ICON = '/image/ic_profile.svg';

export const PAGE_HOME_KEY = 'page__home';
export const PAGE_TOOLS_KEY = 'page__tools';
export const PAGE_STORE_KEY = 'page__finance';
export const PAGE_GAME_KEY = 'page__game__main';
export const PAGE_VIDEO_KEY = 'page__film';
export const PAGE_ENTERTAINMENT_KEY = 'page__entertainment';
export const PAGE_CLUB_KEY = 'page__club';
export const PAGE_PAYMENT_KEY = 'page__finance';
export const PAGE_SEARCH_KEY = 'page__search';

export const LOGO_TEXT_ADDRESS = '/image/svg_ic_logo_text.svg';
export const LOGO_TEXT_ADDRESS_EN = '/image/svg_ic_logo_text_en.svg';
export const LOGO_ICON = '/image/svg_ic_logo.svg';
export const LOGO_WHITE_ICON = '/image/logo_white.png';
export const SEARCH_ICON_ADDRESS = '/image/svg_ic_search.svg';

export const MENU_BUILDER_SETTINGS_URL = 'p/menu/page/v1/';
