export const WIDGET_TOOLS = 'wb__tools';
export const WIDGET_ADVERTISEMENT = 'wb__advertisement';
export const WIDGET_HORIZONTAL = 'wb__horizontal';
export const WIDGET_HORIZONTAL_SQUARE = 'wb__square';
export const WIDGET_HORIZONTAL_CATEGORY = 'wb__rectangular__categories';
export const WIDGET_BANNER_SWIPER = 'wb__banner__swiper';
export const WIDGET_BANNER_SLIDER = 'wb__banner__slider';
export const WIDGET_MEDIA_SWIPER = 'wb__swiper';
export const WIDGET_SHORTCUT_SQUARE_SWIPER = 'wb__square__shortcut__swiper';
export const WIDGET_SHORTCUT_SWIPER = 'wb__shortcut__swiper';
export const WIDGET_FAVORITE = 'wb__favorite';
export const WIDGET_VERTICAL = 'wb__vertical';
export const WIDGET_CAMPAIGNS = 'wb__campaigns';
export const WIDGET_MISSIONS = 'wb__missions';
export const WIDGET_DISCOUNTS = 'wb__discounts';
export const WIDGET_HORIZONTAL_RECTANGULAR = 'wb__horizontal__rectangle';
export const WIDGET_SHORT_VIDEO = 'wb__short__video';
export const WIDGET_WALLET = 'wb__wallet';
export const WIDGET_CALENDAR = 'wb__calendar';
export const WIDGET_EXCHANGE_RATE = 'wb__exchange__rate';

export const IMAGE_PLACEHOLDER_IMAGE = '/image/placeholder.webp';
export const IMAGE_PLACEHOLDER_FULL_IMAGE = '/image/placeholder.svg';

export const ADS_NATIVE_TYPE = 'native';
export const ADS_BANNER_TYPE = 'banner';
export const ADS_ADMOB_TYPE = 'admob_banner';

export const ADS_LARGE_SIZE = 'large';
export const ADS_MEDIUM_SIZE = 'medium';
export const ADS_SMALL_SIZE = 'small';

export const BANNER_LARGE_SIZE = 'large';
export const BANNER_MEDIUM_SIZE = 'medium';
export const BANNER_SMALL_SIZE = 'small';

export const MAIN_APP_URL = 'tlbx.ir';

export const HTTP_KEY = 'http';

export const TLBX_KEY = 'tlbx://';

export const ADS_SWIPER_BREAKPOINTS = {
  '640': {
    slidesPerView: 1,
    spaceBetween: 10
  },
  '768': {
    slidesPerView: 2,
    spaceBetween: 15
  },
  '1024': {
    slidesPerView: 2,
    spaceBetween: 20
  }
};
