import {configureStore} from '@reduxjs/toolkit';
import {menuBuilderReducer, widgetReducer} from '../../page/Main/redux/mainReducer';
import {messageReducer} from './reducers/messageReducer';
import {drugDetailReducer, drugSortReducer} from '../../page/tools/drug_store/redux/drugReducaer';
import {testResultReducer} from '../../page/tools/lab_test_result/redux/testResultReducaer';
import {loginReducer} from '../../page/profile/components/Login/redux/loginReducer';
import {shortcutReducer} from '../../page/Home/components/MenuBuilder/widget/favorite/redux/shortcutReducer';
import {containerReducer} from '../../page/Main/redux/containerReducer';

export const store = configureStore({
  reducer: {
    menuReducer: menuBuilderReducer,
    containerReducer: containerReducer,
    shReducer: shortcutReducer,
    widgetReducer: widgetReducer,
    mReducer: messageReducer,
    drugDetailReducer: drugDetailReducer,
    testResultReducer: testResultReducer,
    drugSortReducer: drugSortReducer,
    loginReducer: loginReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
