import React, {FC, PropsWithChildren, useEffect, useState} from 'react';
import {TypedUseSelectorHook, useSelector} from 'react-redux';
import {RootState} from '../../../../core/redux/store';
import {saveLoginParams} from '../../../../core/services/localStorage.service';
import {LogoutUser} from './logoutUser.component';
import {LoginEnterNumber} from './loginEnterNumber.component';
import LayoutDrawer from '../../../../core/components/layoutDrawer.component';
import {SwipeableDrawer} from '@mui/material';
import {LOGIN_STATE_KEY} from '../../../../core/services/CONSTANTS';
import useLocalStorageState from '../../../../core/utils/useLocalStorage';
import dynamic from 'next/dynamic';
import {WorldModel} from "../../../tools/world_wiki/worldCountry.model";
import {useRouter} from "next/router";

export const LoginContainer: FC<PropsWithChildren> = (props) => {
    const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
    const [isLogin, setLogin] = useLocalStorageState<boolean>(LOGIN_STATE_KEY, false);
    const [resultOpen, setResultOpen] = useState(false);
    const [countryResultOpen, setCountryResultOpen] = useState(false);
    const loginState = useAppSelector((state) => state.loginReducer);
    const [chooseWorldItem, setChooseWorldItem] = useState<WorldModel>();
    const router = useRouter();

    async function countryOpenClick() {
        setCountryResultOpen(true);
    }

    useEffect(() => {
        if (!router.query?.sp) {
            setResultOpen(false);
        }
    },[router.query])

    function getDrawerComponent() {
        if (isLogin) {
            return <LogoutUser cancelClick={() => onCloseBottomSheet(false)} okClick={() => logoutUser()}/>;
        } else {
            return <LoginEnterNumber worldItem={chooseWorldItem} countryClick={() => countryOpenClick()}/>;
        }
    }

    function logoutUser() {
        onCloseBottomSheet(false)
        saveLoginParams('', '', '', '');
        setLogin(false);
    }

    useEffect(() => {
        if (loginState?.logoutUser) {
            setLogin(false);
            if (loginState?.openLoginPage) {
                setResultOpen(true);
                setTimeout(()=>{
                    router.push({search:"sp=1"}, undefined, { shallow: true })
                },500)
            }
        } else if (loginState?.openLoginPage) {
            setChooseWorldItem(undefined)
            setResultOpen(true);
            setTimeout(()=>{
                router.push({search:"sp=1"}, undefined, { shallow: true })
            },500)
        } else {
            setResultOpen(false);
        }
    }, [loginState]);

    function countryItemClick(item:WorldModel) {
        setCountryResultOpen(false);
        setChooseWorldItem(item);
    }

    function getWorldCountryComponent() {
        const WorldCountryComponent = dynamic(() => import('../../../../page/tools/world_wiki/WorldCountryList'), { ssr: false });
        return <WorldCountryComponent itemClick={(item) => countryItemClick(item)}/>;
    }

    function onCloseBottomSheet(open:boolean) {
        router.back()
        setResultOpen(open);
    }

    return (
        <div className={'w-screen h-screen hideScrollBar'}>
            {props.children}
            <SwipeableDrawer
                anchor={'bottom'}
                open={resultOpen}
                disableSwipeToOpen={true}
                PaperProps={{square: false, elevation: 0, style: {backgroundColor: 'transparent'}}}
                onOpen={() => setResultOpen(true)}
                onClose={() => onCloseBottomSheet(!resultOpen)}
            >
                <LayoutDrawer>{getDrawerComponent()}</LayoutDrawer>
            </SwipeableDrawer>

            {countryResultOpen?<SwipeableDrawer
                anchor={'bottom'}
                open={countryResultOpen}
                disableSwipeToOpen={true}
                PaperProps={{square: false, elevation: 0, style: {backgroundColor: 'transparent'}}}
                onOpen={() => setCountryResultOpen(true)}
                onClose={() => setCountryResultOpen(!countryResultOpen)}
            >
                <LayoutDrawer>
                    {getWorldCountryComponent()}
                </LayoutDrawer>
            </SwipeableDrawer>:null}
        </div>
    );
};
