import React, { FC } from 'react';
import { RowComponent } from './base/row.component';
import { ColumnComponent } from './base/column.component';
import Image from 'next/image';

interface ComponentProps {
  title: string;
  image: string;
  onClick: any;
}

export const TitleImageRowComponent: FC<ComponentProps> = (props) => {
  return (
    <ColumnComponent>
      <RowComponent className="items-center p-1 pointer" onClick={props.onClick}>
        <Image fill={true} sizes={'100%'} alt="" src={props.image} className="defaultImage !relative" />
        <div>{props.title}</div>
      </RowComponent>
      <div className={'separatorLine'} />
    </ColumnComponent>
  );
};
