import React, {FC, PropsWithChildren} from 'react';
import {THEME_DARK, THEME_LIGHT, THEME_STATE_KEY} from '../services/CONSTANTS';
import {RowComponent} from './base/row.component';
import {Container} from '@mui/material';
import useLocalStorageState from '../utils/useLocalStorage';
import {Vazirmatn} from "next/font/google";

const vazirmatn = Vazirmatn({ subsets: ['arabic'],variable:'--vazirmatn' });

interface DrawerProps {
  className?: string;
}

const LayoutDrawer: FC<PropsWithChildren<DrawerProps>> = (props) => {
  const [isDark, setDark] = useLocalStorageState<boolean>(THEME_STATE_KEY, false);

  return (
    <RowComponent className={`totalCenter ${props.className} ${vazirmatn.className}`}>
      <Container fixed={true} maxWidth={'md'} className={'!flex flex-col totalCenter'} disableGutters={true}>
        <div className={`AppDrawer ${isDark ? THEME_DARK : THEME_LIGHT} totalCenter w-full md:w-3/4 lg:w-1/2`}>
          <div className="w-full hideScrollBar relative">{props.children}</div>
        </div>
      </Container>
    </RowComponent>
  );
};
export default LayoutDrawer;
