import React, {FC} from 'react';
import {USER_PROFILE_IMAGE} from '../../CONSTANTS';
import {useTranslation} from 'react-i18next';
import {ColumnComponent} from '../../../../core/components/base/column.component';
import {RowComponent} from '../../../../core/components/base/row.component';
import Image from 'next/image';

interface logoutProps {
  cancelClick: any;
  okClick: any;
}

export const LogoutUser: FC<logoutProps> = (props) => {
  const { t } = useTranslation();
  return (
    <ColumnComponent className="fullWidth totalCenter p-3">
      <Image
        fill={true}
        sizes={'100%'}
        alt=""
        src={USER_PROFILE_IMAGE}
        className="userProfileImage ms-2 mt-2 !relative"
      />
      <div className="text-base text-bold mt-2">{t('profile.logoutMessage')}</div>
      <RowComponent className="totalCenter mt-2">
        <div className="redButton text-center pointer" onClick={props.okClick}>
          {t('profile.exitAccount')}
        </div>
        <div className="secondButton text-center pointer" onClick={props.cancelClick}>
          {t('cancel')}
        </div>
      </RowComponent>
    </ColumnComponent>
  );
};
