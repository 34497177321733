import {defer, Observable, take} from 'rxjs';
import initializeAxios from './axiosSetup';
import {axiosRequestConfiguration} from './config';
import {map} from 'rxjs/operators';
import {AxiosRequestHeaders} from 'axios';

const axiosInstance = initializeAxios(axiosRequestConfiguration);

const get = <T>(url: string, queryParams?: object): Observable<T> => {
  return defer(() => axiosInstance.get<T>(url, { params: queryParams }))
    .pipe(map((result) => result.data))
    .pipe(take(1));
};

const head = <R>(url: string): Observable<R> => {
  return defer(() => axiosInstance.head<R, any>(url))
    .pipe(map((result) => result))
    .pipe(take(1));
};

const getWithHeader = <T>(url: string, header: AxiosRequestHeaders, queryParams?: object): Observable<T> => {
  return defer(() => axiosInstance.get<T>(url, { params: queryParams, headers: header }))
    .pipe(map((result) => result.data))
    .pipe(take(1));
};

const post = <T>(url: string, body: object, queryParams?: object): Observable<T | void> => {
  return defer(() => axiosInstance.post<T>(url, body, { params: queryParams }))
    .pipe(map((result) => result.data))
    .pipe(take(1));
};

const postWithHeader = <T>(
  url: string,
  body: object | null,
  header: AxiosRequestHeaders,
  queryParams?: object
): Observable<T | void> => {
  return defer(() => axiosInstance.post<T>(url, body, { params: queryParams, headers: header }))
    .pipe(map((result) => result.data))
    .pipe(take(1));
};

const put = <T>(url: string, body: object, queryParams?: object): Observable<T | void> => {
  return defer(() => axiosInstance.put<T>(url, body, { params: queryParams }))
    .pipe(map((result) => result.data))
    .pipe(take(1));
};

const putWithHeader = <T>(
  url: string,
  body: object | null,
  header: AxiosRequestHeaders,
  queryParams?: object
): Observable<T | void> => {
  return defer(() => axiosInstance.put<T>(url, body, { params: queryParams, headers: header }))
    .pipe(map((result) => result.data))
    .pipe(take(1));
};

const patch = <T>(url: string, body: object, queryParams?: object): Observable<T | void> => {
  return defer(() => axiosInstance.patch<T>(url, body, { params: queryParams }))
    .pipe(map((result) => result.data))
    .pipe(take(1));
};

const deleteWithHeader = <T>(url: string, header: AxiosRequestHeaders): Observable<T | void> => {
  return defer(() => axiosInstance.delete(url, { headers: header }))
    .pipe(map((result) => result.data))
    .pipe(take(1));
};

const updateWithHeader = <T>(url: string, body: object, header: AxiosRequestHeaders): Observable<T | void> => {
  return defer(() => axiosInstance.patch(url, body, { headers: header }))
    .pipe(map((result) => result.data))
    .pipe(take(1));
};

export default {
  get,
  getWithHeader,
  post,
  postWithHeader,
  put,
  patch,
  delete: deleteWithHeader,
  updateWithHeader,
  putWithHeader,
  head
};
