import React, {FC} from 'react';

interface Props {
  width?: string;
  text?: string;
  className?: string;
  onClick?: () => void;
}

export const BlueButton: FC<Props> = (props) => {
  return (
    <div
      className={`mBackgroundBlue pointer align-middle ${
        props.width ?? 'w-full'
      } rounded-lg text-center h-12 text-white ${props.className}`}
      style={{ lineHeight: '3rem' }}
      onClick={props.onClick}
    >
      {props.text ?? ''}
    </div>
  );
};
