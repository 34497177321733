import React, {FC, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {
    COIN_DRAWER_FRAME_IMAGE,
    DRAWER_FRAME_IMAGE,
    USER_FAVORITE_IMAGE,
    USER_LOGIN_IMAGE,
    USER_LOGOUT_IMAGE,
    USER_PROFILE_FILL_IMAGE,
    USER_SETTING_IMAGE
} from './CONSTANTS';
import {getPhoneNumber} from '../../core/services/localStorage.service';
import {FAVORITE, SETTING} from '../../core/navigation/CONSTANTS';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../core/redux/store';
import {LOGIN_ACTION} from './components/Login/redux/loginAction';
import {LOGIN_STATE, LOGOUT_STATE} from './components/Login/CONSTANTS';
import {ENGLISH_APP, LOGIN_STATE_KEY, SCORE_STATE_KEY} from '../../core/services/CONSTANTS';
import {toPersianNumber, toPersianNumberFromNumber} from '../../core/utils/number.utils';
import {RowComponent} from '../../core/components/base/row.component';
import {ColumnComponent} from '../../core/components/base/column.component';
import {TitleImageRowComponent} from '../../core/components/titleImageRowComponent';
import {useRouter} from 'next/router';
import useLocalStorageState from '../../core/utils/useLocalStorage';
import {COIN_IMAGE} from '../club/campaign/CONSTANTS';
import {ScoreService} from '../score/service/score.service';

interface ProfileProps {
  closeDrawer: () => void;
}

export const Profile: FC<ProfileProps> = (props) => {
  const { t } = useTranslation();
  const router = useRouter();

  const [isLogin, setLogin] = useLocalStorageState<boolean>(LOGIN_STATE_KEY, false);
  const [userScore, setUserScore] = useLocalStorageState<number>(SCORE_STATE_KEY, 0);
  let dispatch = useDispatch<AppDispatch>();
  const scoreService = new ScoreService();

  useEffect(() => {
    if (isLogin) {
      scoreService.getScore(1, 5).subscribe((data) => {
        if (data.data && data.data.results && data.data.results.length > 0) {
          setUserScore(data.data.total_point);
        }
      });
    }
  }, [isLogin]);

  function showLoginPage(isLoginState: boolean) {
    if (isLoginState) {
      return () => {
        props.closeDrawer();
        dispatch({
          type: LOGIN_ACTION,
          openLoginPage: true,
          state: LOGIN_STATE
        });
      };
    } else {
      return () => {
        props.closeDrawer();
        dispatch({
          type: LOGIN_ACTION,
          openLoginPage: true,
          state: LOGOUT_STATE
        });
      };
    }
  }

  function favoriteClick() {
    props.closeDrawer();
    if (isLogin) {
      router.push({
        pathname: FAVORITE,
        search: `shTitle=${t('favorite')}`
      });
    } else {
      dispatch({
        type: LOGIN_ACTION,
        openLoginPage: true,
        state: LOGIN_STATE
      });
    }
  }

  return (
    <ColumnComponent>
      <div className="flex flex-col">
        <ColumnComponent className="py-2 relative">
          <img alt={''} src={DRAWER_FRAME_IMAGE} className={'absolute right-0 left-0 top-0'} />
          <div className="w-full flex flex-row px-4 mb-4 items-center pointer h-36 z-30">
            {(`${process.env.NEXT_PUBLIC_NODE_ENV}`.trim() !== ENGLISH_APP)?<RowComponent>
              <img alt="" src={USER_PROFILE_FILL_IMAGE} className="userProfileImage ms-2" />
              {isLogin ? (
                <RowComponent className="items-center">
                  <div className="text-white text-bold text-base ms-2 grow px-2">{toPersianNumber(getPhoneNumber())}</div>
                  <img alt={''} src={USER_LOGOUT_IMAGE} className={'!w-10 p-3'} onClick={showLoginPage(false)} />
                </RowComponent>
              ) : (
                <RowComponent className="items-center ">
                  <img alt="" src={USER_LOGIN_IMAGE} className="defaultImage" onClick={showLoginPage(true)} />
                  <div className="mTextLightBlue text-bold text-sm w-full" onClick={showLoginPage(true)}>
                    {t('profile.loginToAccount')}
                  </div>
                </RowComponent>
              )}
            </RowComponent>:null}
          </div>
          {(`${process.env.NEXT_PUBLIC_NODE_ENV}`.trim() !== ENGLISH_APP)?<ColumnComponent className={'relative h-20 justify-center'}>
            <img alt={''} src={COIN_DRAWER_FRAME_IMAGE} className={'absolute mx-2'} style={{ width: '15rem' }} />
            <ColumnComponent className={'z-30 px-8'}>
              <div className={'text-white text-sm'}>{t('clubText.myScore')}</div>
              <div className="flex flex-row w-auto mt-2">
                <img alt={''} src={COIN_IMAGE} className="littleCoinImage" />
                <div className="text-base mx-3 text-white">{toPersianNumberFromNumber(userScore ?? '?')}</div>
              </div>
            </ColumnComponent>
          </ColumnComponent>:null}
          <TitleImageRowComponent
            title={t('favoriteTools')}
            image={USER_FAVORITE_IMAGE}
            onClick={() => favoriteClick()}
          />
          <TitleImageRowComponent
            title={t('setting')}
            image={USER_SETTING_IMAGE}
            onClick={() => {
              props.closeDrawer();
              router.push({ pathname: SETTING });
            }}
          />
        </ColumnComponent>
      </div>
    </ColumnComponent>
  );
};
