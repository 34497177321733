import React, {CSSProperties, PropsWithChildren} from 'react';

export interface RowProps {
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
  dir?: string;
}

export function RowComponent(props: PropsWithChildren<RowProps>): JSX.Element {
  return (
    <div
      dir={props.dir ?? 'locale'}
      className={`w-full flex flex-row ${props.className}`}
      style={props.style}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
}
