import {AnyAction} from 'redux';
import {MenuBuilderShortcutModel} from '../../../../../../Main/main.model';
import {SHORTCUT_ACTION} from './shortcutAction';

interface ShortcutState {
  item: MenuBuilderShortcutModel | null;
  hasSetting: boolean;
}

const initialShortcutState: ShortcutState = {
  item: null,
  hasSetting: false
};

export function shortcutReducer(state = initialShortcutState, action: AnyAction) {
  switch (action.type) {
    case SHORTCUT_ACTION:
      return {
        item: action.item,
        hasSetting: action.hasSetting
      };
    default:
      return state;
  }
}
