import {AnyAction} from 'redux';
import {LOGIN_STATE} from '../CONSTANTS';
import {LOGIN_ACTION} from './loginAction';

export interface LoginState {
  logoutUser: boolean;
  openLoginPage: boolean;
  state: string;
}

const initialLoginState: LoginState = {
  logoutUser: false,
  openLoginPage: false,
  state: LOGIN_STATE
};

export function loginReducer(state = initialLoginState, action: AnyAction) {
  switch (action.type) {
    case LOGIN_ACTION:
      return {
        logoutUser: action.logoutUser,
        openLoginPage: action.openLoginPage,
        state: action.state
      };
    default:
      return state;
  }
}
