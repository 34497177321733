import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LoginService} from './login.service';
import {Loader} from '../../../../core/components/loader.component';
import Countdown, {CountdownRenderProps} from 'react-countdown';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../../../core/redux/store';
import {MESSAGE_ACTION} from '../../../../core/redux/reducers/messageActions';

import {ENGLISH_APP, LOGIN_STATE_KEY, THEME_STATE_KEY} from '../../../../core/services/CONSTANTS';
import {saveLoginParams} from '../../../../core/services/localStorage.service';
import {LOGIN_ACTION} from './redux/loginAction';
import {LOGIN_STATE} from './CONSTANTS';
import {toPersianNumber} from '../../../../core/utils/number.utils';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {ColumnComponent} from '../../../../core/components/base/column.component';
import CloseIcon from '@mui/icons-material/CloseRounded';
import PinInput from 'react-pin-input';
import {InputAdornment, TextField} from '@mui/material';
import useLocalStorageState from '../../../../core/utils/useLocalStorage';
import {RowComponent} from "../../../../core/components/base/row.component";
import {WorldModel} from "../../../tools/world_wiki/worldCountry.model";
import i18n from "i18next";
import {GoogleLogin} from '@react-oauth/google';

interface LoginProps {
    countryClick: () => void
    worldItem?: WorldModel | null
}

export const LoginEnterNumber: FC<LoginProps> = (props) => {
    const {t} = useTranslation();

    const [phoneNumber, setPhoneNumber] = useState('');
    const [isLoginStep, setLoginStep] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [isCountDownComplete, setCountDownComplete] = useState(false);
    let dispatch = useDispatch<AppDispatch>();
    let enteredCode: string = '';
    const loginService = new LoginService();
    const [chooseWorldItem, setChooseWorldItem] = useState<WorldModel | null>(null);
    const [isIran, setIran] = useState(true);

    const [isLogin, setLogin] = useLocalStorageState<boolean>(LOGIN_STATE_KEY, false);
    const [isDark, setDark] = useLocalStorageState<boolean>(THEME_STATE_KEY, false);

    useEffect(() => {
        if (props?.worldItem) {
            setIran(props?.worldItem?.Code === "IR");
        } else {
            setIran(true);
        }
        setChooseWorldItem(props.worldItem ?? null);
    }, [props.worldItem])


    const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
        const number = event.currentTarget.value.slice(0, 12).replace(RegExp('^09'), '9');
        setPhoneNumber(number);
    };

    function onEnterPhoneNumberClick() {
        let regex = new RegExp(isIran ? '9[0-9]{9}$' : '')
        if (regex.test(phoneNumber)) {
            setLoading(true);
            if (isIran) {
                loginService.getVerificationCode(phoneNumber).subscribe(
                    (data) => {
                        setLoading(false);
                        setLoginStep(false);
                    },
                    (error) => {
                        setLoading(false);
                        dispatch({
                            type: MESSAGE_ACTION,
                            message: error?.message ?? t('profile.generalError'),
                            sort: 'error'
                        });
                    },
                    null
                );
            } else {
                loginService.getVerificationCodeForeign(chooseWorldItem?.Tel + phoneNumber, chooseWorldItem?.Code.toString() ?? "98").subscribe(
                    (data) => {
                        setLoading(false);
                        setLoginStep(false);
                    },
                    (error) => {
                        setLoading(false);
                    },
                    null
                );
            }
        } else {
            dispatch({
                type: MESSAGE_ACTION,
                message: t('error.numberNotValid'),
                sort: 'error'
            });
        }
    }

    function onCodeEntered() {
        if (isCountDownComplete) {
            setLoading(true);
            if (isIran) {
                loginService.getVerificationCode(phoneNumber).subscribe(
                    (data) => {
                        setLoading(false);
                        setLoginStep(false);
                    },
                    (error) => {
                        setLoading(false);
                        dispatch({
                            type: MESSAGE_ACTION,
                            message: error?.message ?? t('profile.generalError'),
                            sort: 'error'
                        });
                    }
                );
            } else {
                loginService.getVerificationCodeForeign(chooseWorldItem?.Tel + phoneNumber, chooseWorldItem?.Code.toString() ?? "IR").subscribe(
                    (data) => {
                        setLoading(false);
                        setLoginStep(false);
                    },
                    (error) => {
                        setLoading(false);
                    }
                );
            }
            setCountDownComplete(false);
        } else {
            if (isIran) {
                loginService.getVerifyCode(enteredCode, phoneNumber).subscribe(
                    (data) => {
                        if (data && data.data?.access) {
                            dispatch({
                                type: MESSAGE_ACTION,
                                message: t('profile.loginCorrectly'),
                                sort: 'success'
                            });
                            saveLoginParams(data.data.access, data.data.refresh, '0' + phoneNumber, data.data.user_id.toString());
                            dispatch({
                                type: LOGIN_ACTION,
                                openLoginPage: false,
                                state: LOGIN_STATE
                            });
                            setLogin(true);
                        }
                    },
                    (error) => {
                        setLoading(false);
                        dispatch({
                            type: MESSAGE_ACTION,
                            message: error?.message ?? t('profile.enterCodeNotCorrect'),
                            sort: 'error'
                        });
                    }
                );
            } else {
                loginService.getVerifyCodeForeign(enteredCode, chooseWorldItem?.Tel + phoneNumber, chooseWorldItem?.Code.toString() ?? "IR").subscribe(
                    (data) => {
                        if (data && data.data?.access) {
                            dispatch({
                                type: MESSAGE_ACTION,
                                message: t('profile.loginCorrectly'),
                                sort: 'success'
                            });
                            saveLoginParams(data.data.access, data.data.refresh, '0' + phoneNumber, data.data.user_id.toString());
                            dispatch({
                                type: LOGIN_ACTION,
                                openLoginPage: false,
                                state: LOGIN_STATE
                            });
                            setLogin(true);
                        }
                    },
                    (error) => {
                        setLoading(false);
                    }
                );
            }
        }
    }

    function resetNumberClick() {
        return () => {
            setPhoneNumber('');
            enteredCode = '';
            setLoginStep(true);
        };
    }

    const renderer = (props: CountdownRenderProps | any) => {
        if (props.completed) {
            setCountDownComplete(true);
            return <div/>;
        } else {
            return (
                <div className="flex flex-row mt-4">
                    <div className="mText fs-7 text-bold">
                        {t('profile.second', {second: toPersianNumber(props.seconds.toString())})}
                    </div>
                    <div className="fs-7 mTextLighter mx-1">{t('profile.untilResendCode')}</div>
                </div>
            );
        }
    };

    function onLoginNumberKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
        if (event.keyCode == 13) {
            event.preventDefault();
            onEnterPhoneNumberClick();
        }
    }

    function closeLogin() {
        dispatch({
            type: LOGIN_ACTION,
            openLoginPage: false,
            state: LOGIN_STATE
        });
    }

    function countryClick() {
        props.countryClick()
    }

    return (
        <ColumnComponent>
            <ColumnComponent className="p-4 justify-center relative">
                <ColumnComponent className="relative px-4">
                    <CloseIcon className="closeIcon" fontSize={'large'} onClick={() => closeLogin()}/>
                    <div className="text-bold text-base mx-8 mt-4 font">
                        {isLoginStep ? t('profile.loginSignUp') : t('profile.authentication')}
                    </div>
                </ColumnComponent>
                <ColumnComponent className="totalCenter mt-6">
                    {isLoginStep ? (
                        <ColumnComponent className="totalCenter">
                            <TextField
                                value={""}
                                InputProps={{
                                    style: {
                                        cursor: "pointer",
                                        padding: '0.2rem',
                                    },
                                    startAdornment: (
                                        <InputAdornment onClick={() => countryClick()} className={"!w-full"}
                                                        position={'start'}>
                                            <RowComponent className={"items-center px-4"}>
                                                <img
                                                    alt={chooseWorldItem?.EnglishName ?? "FA"}
                                                    src={`/flags/${chooseWorldItem?.EnglishName ?? "Iran"}.png`}
                                                    className="rounded-full"
                                                    style={{width: '1.5rem', height: '1.5rem'}}
                                                />
                                                <div
                                                    className={"px-2 text-black"}>{chooseWorldItem?.Name ?? "ایران"}</div>
                                            </RowComponent>
                                        </InputAdornment>
                                    ),
                                }}
                                disabled={true}
                                autoComplete="off"
                                className="searchBox mb-3 mt-1 pointer"
                                label={t('profile.country')}
                                variant={'outlined'}
                            />
                            <TextField
                                autoFocus
                                value={phoneNumber}
                                style={{direction: 'ltr', marginTop: "1rem"}}
                                onKeyDown={(event) => onLoginNumberKeyDown(event)}
                                InputProps={{
                                    inputMode: 'numeric',
                                    style: {
                                        padding: '0.2rem'
                                    },
                                    startAdornment: (
                                        <InputAdornment position={'start'}>
                                            <div className={'mTextLighter px-1'} style={{direction: 'ltr'}}>
                                                +{chooseWorldItem?.Tel ?? "98"} |
                                            </div>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position={'start'}>
                                            <HighlightOffIcon
                                                style={{display: phoneNumber ? 'block' : 'none'}}
                                                className={`mTextLightBlue mb-1`}
                                                onClick={() => setPhoneNumber('')}
                                            />
                                        </InputAdornment>
                                    )
                                }}
                                focused
                                autoComplete="off"
                                type={'number'}
                                onChange={onChangeText}
                                className="searchBox mb-3 mt-1"
                                label={t('profile.phoneNumber')}
                                variant={'outlined'}
                            />
                            <div className="mTextLightBlue mx-2 text-center mt-2 p-1 text-xs">
                                <span>{t('profile.withEnter')}</span>
                                <span
                                    className="mTextBlue pointer"
                                    onClick={() =>
                                        typeof window !== 'undefined'
                                            ? window.open((`${process.env.NEXT_PUBLIC_NODE_ENV}`.trim() == ENGLISH_APP) ? "https://tlbxapp.com/terms" : "https://tlbx.ir/terms", '_self')
                                            : null
                                    }
                                >{t('profile.termOfService')}</span>
                                <span>{t('profile.and')}</span>
                                <span
                                    className="mTextBlue pointer"
                                    onClick={() =>
                                        typeof window !== 'undefined'
                                            ? window.open((`${process.env.NEXT_PUBLIC_NODE_ENV}`.trim() == ENGLISH_APP) ? "https://tlbxapp.com/privacy" : "https://tlbx.ir/privacy", '_self')
                                            : null
                                    }
                                >{t('profile.privacyPolicy')}</span>
                                <span>{t('profile.iAccept')}</span>
                            </div>
                            <ColumnComponent className="relative totalCenter ">
                                <div
                                    style={{opacity: isLoading ? 0.5 : 1}}
                                    className={`enterButton mt-2 text-center text-bold text-base pointer w-90 ${
                                        isLoading ? 'mTextBlue' : 'text-white'
                                    }`}
                                    onClick={() => onEnterPhoneNumberClick()}
                                >
                                    {t('profile.receiveEnteredCode')}
                                </div>
                                {isLoading ? (
                                    <div className="mainLoaderLogin">
                                        <Loader isFullScreen={false}/>
                                    </div>
                                ) : null}
                            </ColumnComponent>
                        </ColumnComponent>
                    ) : (
                        <ColumnComponent className="totalCenter">
                            <div className="mText px-4 fs-7 p-1 text-start w-full">
                                {t('profile.authenticationMessage', {phone: toPersianNumber('0' + phoneNumber)})}
                            </div>
                            <div className="px-4 pt-2 text-base mTextGold text-start w-full"
                                 onClick={resetNumberClick()}>
                                {t('profile.editPhoneNumber')}
                            </div>
                            <PinInput
                                length={4}
                                initialValue=""
                                onChange={(value: string) => {
                                    enteredCode = value;
                                }}
                                type="numeric"
                                inputMode="number"
                                style={{
                                    width: '90%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    paddingTop: '.5rem',
                                    paddingBottom: '.5rem',
                                    paddingLeft: '1.5rem',
                                    paddingRight: '1.5rem',
                                    borderRadius: '.5rem',
                                    marginTop: '1rem',
                                    direction: 'ltr',
                                    borderWidth: '.1rem',
                                    borderColor: 'var(--theme-color-green-light)'
                                }}
                                inputStyle={{
                                    borderBottomColor: 'grey',
                                    borderWidth: '0',
                                    borderBottomWidth: '.2rem',
                                    borderRadius: '.2rem',
                                    color: isDark ? 'white' : 'black'
                                }}
                                inputFocusStyle={{
                                    borderBottomColor: 'blue',
                                    borderWidth: '0',
                                    borderBottomWidth: '.2rem',
                                    borderRadius: '.2rem',
                                    color: 'blue'
                                }}
                                onComplete={(value: string) => {
                                    enteredCode = value;
                                    onCodeEntered();
                                }}
                                autoSelect={true}
                                focus={true}
                            />
                            {isCountDownComplete ? null : (
                                <Countdown date={Date.now() + 60000} intervalDelay={0} renderer={renderer}/>
                            )}
                            {isIran ? null :
                                <ColumnComponent className={"mt-4 totalCenter"}>
                                    <GoogleLogin useOneTap={true} text={"signin_with"} locale={i18n.language}
                                                 onSuccess={credentialResponse => {
                                                     console.log(credentialResponse.credential)
                                                     loginService.loginWithGoogle(credentialResponse.credential ?? "").subscribe((data) => {
                                                         if (data && data.data?.access) {
                                                             dispatch({
                                                                 type: MESSAGE_ACTION,
                                                                 message: t('profile.loginCorrectly'),
                                                                 sort: 'success'
                                                             });
                                                             saveLoginParams(data.data.access, data.data.refresh, `${data.data.first_name} ${data.data.last_name}`, data.data.user_id.toString());
                                                             dispatch({
                                                                 type: LOGIN_ACTION,
                                                                 openLoginPage: false,
                                                                 state: LOGIN_STATE
                                                             });
                                                             setLogin(true);
                                                         }
                                                     })
                                                 }}
                                                 onError={() => {
                                                     console.log('Login Failed');
                                                 }}
                                    />
                                </ColumnComponent>}
                            <div
                                className="enterButton mt-4 text-center text-bold text-base pointer w-90"
                                onClick={() => onCodeEntered()}
                            >
                                {isCountDownComplete ? t('profile.resendCode') : t('profile.enter')}
                            </div>
                        </ColumnComponent>
                    )}
                </ColumnComponent>
            </ColumnComponent>
        </ColumnComponent>
    );
};
