import {AnyAction} from 'redux';
import {DrugsModel} from '../model/allDrugs.model';
import {DRUG_DETAIL_ACTION, DRUG_SORT_ACTION} from './drugAction';
import {DrugsDarmaniGroupModel} from '../model/drugsDarmaniGroup.model';

export interface DrugState {
  item: DrugsModel | null;
}

const initialDrugState: DrugState = {
  item: null
};

export interface DrugSortState {
  item: DrugsDarmaniGroupModel | null;
}

const initialDrugSortState: DrugSortState = {
  item: null
};

export function drugDetailReducer(state = initialDrugState, action: AnyAction) {
  switch (action.type) {
    case DRUG_DETAIL_ACTION:
      return {
        item: action.item
      };
    default:
      return state;
  }
}

export function drugSortReducer(state = initialDrugSortState, action: AnyAction) {
  switch (action.type) {
    case DRUG_SORT_ACTION:
      return {
        item: action.item
      };
    default:
      return state;
  }
}
