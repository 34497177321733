import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

interface BackIconProps {
  onClick: any;
  color?: string;
}

export const BackIconComponent: FC<BackIconProps> = (props) => {
  const { i18n } = useTranslation();
  return (
    <div className="w-auto pointer m-1" onClick={props.onClick}>
      {i18n.dir() == 'rtl' ? (
        <ArrowForwardIosRoundedIcon style={{ color: props.color ?? 'var(--theme-page-text)' }} fontSize={'medium'} />
      ) : (
        <ArrowBackIosRoundedIcon style={{ color: props.color ?? 'var(--theme-page-text)' }} fontSize={'medium'} />
      )}
    </div>
  );
};
