export const MAFIA = '/t/mafia/';
export const MAFIA_NO_SLASH = '/t/mafia';
export const NEWS = '/t/news/';
export const NEWS_SAVED = '/t/news/saved';
export const NEWS_DETAIL = '/t/news/detail/:id';
export const NEWS_CATEGORY = '/t/news/categories';
export const NEWS_CATEGORY_POSTS = '/t/news/categoryPosts/:id';
export const EXCHANGE_RATE = '/t/exchange_rate/';
export const QURAN = '/t/quran/';
export const QURAN_MAIN = '/tools/quran/';
export const PAINT = '/t/paint';
export const QURAN_SORE = '/t/quran/sore/:id';
export const NATIONAL_CODE2 = '/t/national_code';
export const NATIONAL_CODE = '/t/national_code';
export const SHORT_VIDEO_PATH = '/t/short_video/';
export const PELAK2 = '/t/pelak';
export const PELAK = '/t/car_plates';
export const PREDICT = '/t/predict';
export const PREDICT_LEAGUE = '/t/predict_league';
export const PREDICT_LEAGUE_DETAIL = '/t/predict_league/:id';
export const PREDICT_LIST = '/t/predictList/:active';
export const PREDICT_DETAIL = '/t/predictDetail/:id';
export const DATE_CONVERTER = '/t/date_converter';
export const CITY_CODE2 = '/t/city_codes';
export const CITY_CODE = '/t/city_codes';
export const HOUSE_TRANSFER_PRICE2 = '/t/houseTransferPrice';
export const HOUSE_TRANSFER_PRICE = '/t/conveyance_deed_cost';
export const CAR_TRANSFER_PRICE2 = '/t/carTransferPrice';
export const CAR_TRANSFER_PRICE = '/t/car_conveyance_cost';
export const BRT_MAP = '/t/brt_map';
export const TIME_INTERVAL = '/t/time_zone_difference';
export const SUBWAY_MAP = '/t/subway_map';
export const GOLD_PRICE2 = '/t/goldPrice';
export const GOLD_PRICE = '/t/gold_price';
export const OIL_FAT2 = '/t/oilFat';
export const OIL_FAT = '/t/oil_fat';
export const CALCULATOR = '/t/calculator';
export const CHANCE = '/t/fortune_wheel';
export const NUTRIENT = '/t/nutrient';
export const WORLD_COUNTRY2 = '/t/world_wiki';
export const WORLD_COUNTRY = '/t/world_wiki';
export const PROFILE = '/profile';
export const SETTING = '/setting';
export const SHORTCUT_LIST = '/shortcutList';
export const SEARCH = '/shortcutList';
export const TEST_RESULT = '/t/lab_test_result';
export const TEST_RESULT_Detail = '/t/labTestResultDetail';
export const FAVORITE = '/favorite';
export const DRUGSTORE = '/t/drug_store';
export const DISCOUNT = '/club/discount';
export const MY_DISCOUNT = '/club/myDiscount';
export const SCORE = '/club/score';
export const CLUB = '/club';
export const MENU_BUILDER = '/menu/:page';
export const CAMPAIGN = '/club/campaign';
export const MISSION = '/club/mission';
export const CAMPAIGN_DETAIL = '/club/campaign/:id';
export const CORONA = '/t/corona_stats';
export const CORONA2 = '/t/corona_stats';
export const NOTEBOOK = '/t/notebook';
export const NOTEBOOK_MAIN = '/tools/notebook';
export const NOTEBOOK_FOLDER = '/t/notebook/:id';
export const ADD_NOTE = '/t/notebook/add';
export const ADD_NOTE_MAIN = '/tools/notebook/add';
export const EDIT_NOTE = '/t/notebook/add/:id';
export const EDIT_NOTE_MAIN = '/tools/notebook/add/:id';
export const BARCODE_SCANNER = '/t/barcode_scanner';
export const BARCODE_SCANNER2 = '/t/code__scanner__box';
export const CALENDAR = '/t/calendar';
export const VIDEO_GARDI = '/t/:type/video';
export const PLAYER_VIDEO = '/t/:tag/video/:id';
export const TEST_CACHE = '/t/testG';
export const HAFEZ = '/t/hafiz';
export const HAFEZ_MAIN = '/tools/hafiz';
export const HAFEZ2 = '/t/hafiz';
export const GENERAL_WEB = '/t/generalWeb';
export const NAHJ = '/t/nahjolbalaghe';
export const NAHJ_HEKMAT = '/t/nahjolbalaghe/hekmat';
export const NAHJ_HEKMAT_MAIN = '/tools/nahjolbalaghe/hekmat';
export const RAMAZAN_DOA_DAY = '/t/ramazan/doa';
export const RAMAZAN_DOA_DAY_DETAIL = '/t/ramazan/doa/:id';
export const NAHJ_HEKMAT_DETAIL = '/t/nahjolbalaghe/hekmat/:id';
export const NAHJ_NAME = '/t/nahjolbalaghe/name';
export const NAHJ_NAME_MAIN = '/tools/nahjolbalaghe/name';
export const NAHJ_NAME_DETAIL = '/t/nahjolbalaghe/name/:id';
export const NAHJ_KHOTBE = '/t/nahjolbalaghe/khotbe';
export const NAHJ_KHOTBE_MAIN = '/tools/nahjolbalaghe/khotbe';
export const NAHJ_KHOTBE_DETAIL = '/t/nahjolbalaghe/khotbe/:id';
export const HAFEZ_DETAIL = '/t/hafiz/:id';
export const HAFEZ2_DETAIL = '/t/hafiz/:id';
export const DRUG_DETAIL = '/t/drugDetail';
export const SORTED_DRUGS = '/t/sortedDrugs';
export const BUY_CHARGE = '/t/buy_charge';
export const MOBILE_BILL = '/t/mobile_bill';
export const ELECTRICITY_BILL = '/t/electricity_bill';
export const GAS_BILL = '/t/gas_bill';
export const WATER_BILL = '/t/water_bill';
export const PHONE_BILL = '/t/phone_bill';
export const PAYMENT_BILL = '/t/bill_payment';
export const SERVICE_BILL_NEW = '/t/bill/:id';
export const BUY_INTERNET = '/t/buy_internet_package';
export const FINANCIAL_HISTORY = '/t/transactions';
export const FINANCIAL_HISTORY_MAIN = '/tools/transactions';
export const WALLET = '/t/wallet';
export const FINANCIAL_HISTORY_DETAIL = '/t/transactions/:id';
export const BASE_ROOT = '/';
export const NOT_FOUND = '*';
