import React, {PropsWithChildren} from "react";
import Link from "next/link";

export interface LinkProps {
    pathname: string;
    query: any;
    isOpenWindow: boolean;
}

export function LinkComponent(props: PropsWithChildren<LinkProps>): JSX.Element {
    return <>
        {props.isOpenWindow ?
            <span onClick={()=>(typeof window !== 'undefined')?window.open(props.pathname, '_self'):null}>
                {props.children}
            </span> :
            <Link target={props.isOpenWindow ? "_self" : ""} href={{pathname: props.pathname, query: props.query}}
                  passHref={props.isOpenWindow}>
                {props.children}
            </Link>
        }
    </>
}
