import React, {CSSProperties, LegacyRef, PropsWithChildren} from 'react';

export interface ColumnProps {
  className?: string;
  dir?: string;
  style?: CSSProperties;
  onClick?: () => void;
  ref?: LegacyRef<HTMLDivElement>;
  id?: string;
}

export function ColumnComponent(props: PropsWithChildren<ColumnProps>): JSX.Element {
  return (
    <div id={props.id}
      dir={props.dir ?? 'locale'}
      ref={props.ref ?? undefined}
      className={`w-full flex flex-col ${props.className}`}
      onClick={props.onClick}
      style={props.style}
    >
      {props.children}
    </div>
  );
}
