import {AnyAction} from 'redux';
import {CONTAINER_ACTION} from './mainAction';

export interface ContainerState {
  state: string;
  route: string;
}

const initialContainerState: ContainerState = {
  state: '',
  route: ''
};

export function containerReducer(state = initialContainerState, action: AnyAction) {
  switch (action.type) {
    case CONTAINER_ACTION:
      return {
        state: action.state,
        route: action.route
      };
    default:
      return state;
  }
}
