import {Observable} from 'rxjs';
import Api from '../../../../core/services/Api';
import {
  DELETE_ACCOUNT_CODE_URL,
  GET_LOGIN_WITH_GOOGLE_URL,
  GET_VERIFICATION_CODE_FOREIGN_URL,
  GET_VERIFICATION_CODE_URL,
  GET_VERIFY_CODE_FOREIGN_URL,
  GET_VERIFY_CODE_URL
} from './CONSTANTS';
import {LoginMainModel, VerifyMainModel} from './login.model';
import {getAppPackage} from '../../../../core/services/localStorage.service';

const { versionCode } = require('/package.json');

export class LoginService {
  public getVerificationCode(phoneNumberModel: string): Observable<LoginMainModel | void> {
    return Api.post<LoginMainModel>(GET_VERIFICATION_CODE_URL, { mobile: '0' + phoneNumberModel });
  }

  public getVerificationCodeForeign(phoneNumberModel: string,countryCode:string): Observable<LoginMainModel | void> {
    return Api.post<LoginMainModel>(GET_VERIFICATION_CODE_FOREIGN_URL, { mobile: phoneNumberModel.toString().padStart(13, '0'),code:countryCode });
  }

  public getVerifyCode(code: string, mobile: string): Observable<VerifyMainModel | void> {
    return Api.post<VerifyMainModel>(GET_VERIFY_CODE_URL, {
      active_code: code,
      app_version: `${versionCode}`,
      mobile: '0' + mobile,
      package_name: getAppPackage()
    });
  }

  public getVerifyCodeForeign(code: string, mobile: string,countryCode:string): Observable<VerifyMainModel | void> {
    return Api.post<VerifyMainModel>(GET_VERIFY_CODE_FOREIGN_URL, {
      active_code: code,
      code: countryCode,
      app_version:`${versionCode}`,
      mobile: mobile.padStart(13, '0'),
      package_name: getAppPackage()
    });
  }

  public loginWithGoogle(token: string): Observable<VerifyMainModel | void> {
    return Api.post<VerifyMainModel>(GET_LOGIN_WITH_GOOGLE_URL, {
      auth_token: token,
      app_version:`${versionCode}`,
      provider: "google",
      package_name: getAppPackage()
    });
  }

  public deleteAccount(email:string): Observable<LoginMainModel | void> {
    return Api.post<LoginMainModel>(DELETE_ACCOUNT_CODE_URL, { email: email });
  }
}
