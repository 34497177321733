export const VIDEO_BOARD_URL = 'https://vod.jabeabzarapp.com/api/v1/board/';
export const FILM_BOARD_URL = 'https://vod2.jabeabzarapp.com/api/v1/board/';
export const BOARD_VIDEO_URL = 'https://vod.jabeabzarapp.com/api/v2/media/';
export const BOARD_FILM_URL = 'https://vod2.jabeabzarapp.com/api/v2/media/';

export const SHARE_IMAGE_URL = '/image/svg_ic_share.svg';

export const VIDEO_BOX_ENDPOINT = 'https://tlbx.ir/t/';
export const SHARE_VIDEO_SUFFIX = 'clip/video/';
export const SHARE_FILM_SUFFIX = 'film/video/';

export const VIDEO_TAG = 'clip';
export const FILM_TAG = 'film';

export const VIDEO_GARDI_PATH = '/t/clip/video';
export const FILM_SERIAL_PATH = '/t/film/video';

export const VIDEO_BASE_MEDIA = 'https://vod.jabeabzarapp.com/';
export const FILM_BASE_MEDIA = 'https://vod2.jabeabzarapp.com/';

export const ADS_ADIVERY_URL =
  'https://fetch.adivery.com/api/v1/vast?placement_id=3e8e16c4-c351-4af4-8448-653664728a33&app_id=97799da1-611e-4526-bccf-d607d7fcda00';
