import React, {useEffect} from 'react';
import '../core/style/App.scss';
import '../core/style/Theme.scss';
import '../core/style/tailwind.scss';
import '../page/tools/calculator/style.scss';
import '../page/tools/bill/style.scss';
import '../page/tools/predict/style.scss';
import '../page/tools/video/style/style.scss';
import '../page/tools/notebook/style.scss';
import '../page/tools/paint/style.scss';
import '../page/tools/generalWeb/style.scss';
import '../page/tools/quran/style/style.scss';
import '../page/tools/barcode_scanner/style.scss';
import '../page/tools/calendar/style.scss';
import '../page/tools/corona_stats/style.scss';
import '../page/tools/date_converter/style.scss';
import '../page/tools/ramazan/style.scss';
import '../page/tools/drug_store/style/style.scss';
import '../page/tools/fortune_wheel/style.scss';
import '../page/tools/nahjolbalaghe/style.scss';
import '../page/tools/national_code/style.scss';
import '../page/tools/car_plates/style.scss';
import 'video.js/dist/video-js.css';
import 'react-medium-image-zoom/dist/styles.css';
import 'reactjs-popup/dist/index.css';

import '../page/Main/style/style.scss';
import '../page/Home/components/MenuBuilder/style/style.scss';
import '../page/Home/components/MenuBuilder/widget/favorite/style.scss';
import '../page/club/style.scss';
import '../page/club/campaign/style.scss';
import '../page/club/Discount/style.scss';
import '../page/profile/style/style.scss';
import '../page/tools/periodic/style.scss';
import '../core/components/ui/bottomNav/style.scss';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/scrollbar';
import type {AppProps} from 'next/app';
import {store} from '../core/redux/store';
import {Provider} from 'react-redux';
import ContainerLayout from '../core/components/layout.component';
import {LoginContainer} from '../page/profile/components/Login/loginContainer.component';
import {CacheProvider} from '@emotion/react';
import createCache from '@emotion/cache';
import {prefixer} from 'stylis';
import {StyleSheetManager} from 'styled-components';
import rtlPlugin from 'stylis-plugin-rtl';
import {ENGLISH, ENGLISH_APP, PERSIAN, PRODUCTION_APP} from '../core/services/CONSTANTS';
import {useTranslation} from 'react-i18next';
import {DefaultSeo} from 'next-seo';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import {AnimatePresence} from 'framer-motion';
import {ColumnComponent} from '../core/components/base/column.component';
import {RowComponent} from '../core/components/base/row.component';
import {createTheme, Stack, ThemeProvider} from '@mui/material';
import {
    APARAT_IMAGE,
    APARAT_URL,
    CAFE_DOWNLOAD_IMAGE,
    CAFE_TOOLBOX_URL,
    DIRECT_CAFE_TOOLBOX_URL,
    DIRECT_DOWNLOAD_EN_IMAGE,
    DIRECT_DOWNLOAD_IMAGE,
    DIRECT_PLAY_TOOLBOX_URL,
    ENAMAD_IMAGE,
    ENAMAD_URL,
    INSTAGRAM_IMAGE,
    INSTAGRAM_URL,
    LINKEDIN_IMAGE,
    LINKEDIN_URL,
    MYKET_DOWNLOAD_IMAGE,
    MYKET_TOOLBOX_URL,
    PLAY_DOWNLOAD_IMAGE,
    PLAY_TOOLBOX_URL,
    REZI_IMAGE,
    REZI_URL,
    SENFI_IMAGE,
    SENFI_URL
} from '../page/profile/CONSTANTS';
import Link from 'next/link';
import Image from 'next/image';
import {Vazirmatn} from 'next/font/google';
import {LOGO_WHITE_ICON} from "../page/Main/CONSTANTS";
import {GoogleOAuthProvider} from '@react-oauth/google';

const vazirmatn = Vazirmatn({subsets: ['arabic'], variable: '--vazirmatn'});
const theme = createTheme({
    typography: {
        fontFamily: vazirmatn.style.fontFamily,
    }
});

function MyApp({Component, pageProps}: AppProps) {
    Sentry.init({
        dsn: process.env.NEXT_PUBLIC_SENTRY_PROJECT_DSN,
        tracesSampleRate: 0.1,
        replaysSessionSampleRate: (`${process.env.NEXT_PUBLIC_NODE_ENV}`.trim() == PRODUCTION_APP) ? 0.05 : 1.0,
        replaysOnErrorSampleRate: (`${process.env.NEXT_PUBLIC_NODE_ENV}`.trim() == PRODUCTION_APP) ? 0.2 : 1.0,
        environment: process.env.NEXT_PUBLIC_MODE,
        integrations: [new Integrations.BrowserTracing()],
    });


    const TRACKING_ID = process.env.NEXT_PUBLIC_ANALYTICS_TOKEN ?? ''; // YOUR_OWN_TRACKING_ID
    const TOOLBOX_TRACKING_ID = process.env.NEXT_PUBLIC_TOOLBOX_ANALYTICS_TOKEN ?? ''; // YOUR_OWN_TRACKING_ID
    ReactGA.initialize(`${process.env.NEXT_PUBLIC_NODE_ENV}`.trim() == ENGLISH_APP ? TOOLBOX_TRACKING_ID : TRACKING_ID);
    const {t, i18n} = useTranslation();
    useEffect(() => {
    }, [i18n.language])

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            if (typeof window !== 'undefined')
                window.addEventListener('load', function () {
                    navigator.serviceWorker.register('/sw.js', {scope: '/'}).then(
                        function (registration) {
                            console.log('Service Worker registration successful with scope: ', registration.scope);
                        },
                        function (err) {
                            console.log('Service Worker registration failed: ', err);
                        }
                    );
                });
        }
    }, []);

    useEffect(() => {
        document.body.dir = i18n.dir();
    }, [i18n.dir()]);

    const cacheRtl = createCache({
        key: 'muirtl',
        stylisPlugins: [prefixer, rtlPlugin]
    });

    const cache = createCache({
        key: 'mui'
    });

    function clickEnamad() {
        if (typeof window !== 'undefined') {
            window.open(ENAMAD_URL);
        }
    }

    function clickREZI() {
        if (typeof window !== 'undefined') {
            window.open(REZI_URL);
        }
    }

    function openUrl(url: string) {
        if (typeof window !== 'undefined') window.open(url);
    }

    return (
        <>
            <DefaultSeo
                title={t('appName')}
                description={t('appDesc')}
                openGraph={{
                    type: 'website',
                    locale: i18n.language === PERSIAN ? 'fa_IR' : 'en_US',
                    url: 'https://tlbx.ir/',
                    siteName: `${t('appName')}`,
                    images: [
                        {
                            url: 'https://tlbx.ir/icon.png',
                            width: 192,
                            height: 192,
                            type: 'image/png'
                        }
                    ]
                }}
                themeColor={'#000'}
            />
            <main className={vazirmatn.className}>
                <GoogleOAuthProvider clientId={`${process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID_TOKEN}`}>
                    <Provider store={store}>
                        <CacheProvider value={i18n.language === PERSIAN ? cacheRtl : cache}>
                            <StyleSheetManager>
                                <ThemeProvider theme={theme}>
                                    <LoginContainer>
                                        <ColumnComponent>
                                            <ContainerLayout>
                                                <ColumnComponent
                                                    className={'md:border-solid md:border-b-4 border-b-slate-200/[.3] md:min-h-screen relative'}>
                                                    <AnimatePresence mode="wait" initial={true}>
                                                        <Component {...pageProps} />
                                                    </AnimatePresence>
                                                </ColumnComponent>
                                                <ColumnComponent className={'hidden md:flex bottom-0 mt-10 mb-10 px-4'}>
                                                    <div
                                                        className={'text-ellipsis text-2xl'}>{t('profile.toolboxTitle')}</div>
                                                    <div
                                                        className={'text-ellipsis text-base mt-2'}>{t('profile.aboutToolbox')}</div>
                                                    <RowComponent className={'px-2'}>
                                                        <div className={'flex flex-col mt-10 w-2/3'}>
                                                            <div className={'flex-wrap flex flex-row mt-8 w-4/5'}>
                                                                <Link href={'/page/privacy'}>
                                                                    <div
                                                                        className={'mx-4 mt-1 text-base hover:text-green-600 pointer h-8'}>
                                                                        {t('profile.privacy')}
                                                                    </div>
                                                                </Link>
                                                                <Link href={'/page/terms'}>
                                                                    <div
                                                                        className={'mx-4 mt-1 text-base hover:text-green-600 pointer h-8'}>
                                                                        {t('profile.rules')}
                                                                    </div>
                                                                </Link>
                                                                <Link href={'/page/contact'}>
                                                                    <div
                                                                        className={'mx-4 mt-1 text-base hover:text-green-600 pointer h-8'}>
                                                                        {t('profile.contactUs')}
                                                                    </div>
                                                                </Link>
                                                                <Link href={'/page/about'}>
                                                                    <div
                                                                        className={'mx-4 mt-1 text-base hover:text-green-600 pointer h-8'}>
                                                                        {t('profile.aboutUs')}
                                                                    </div>
                                                                </Link>
                                                                <Link href={'/page/ads'}>
                                                                    <div
                                                                        className={'mx-4 mt-1 text-base hover:text-green-600 pointer h-8'}>
                                                                        {t('profile.ads')}
                                                                    </div>
                                                                </Link>
                                                                <Link href={'/page/blog'}>
                                                                    <div
                                                                        className={'mx-4 mt-1 text-base hover:text-green-600 pointer h-8'}>
                                                                        {t('profile.blog')}
                                                                    </div>
                                                                </Link>
                                                                <Link href={'/page/business'}>
                                                                    <div
                                                                        className={'mx-4 mt-1 text-base hover:text-green-600 pointer h-8'}>
                                                                        {t('profile.business')}
                                                                    </div>
                                                                </Link>
                                                                <Link href={'/page/faq'}>
                                                                    <div
                                                                        className={'mx-4 mt-1 text-base hover:text-green-600 pointer h-8'}>
                                                                        {t('profile.faq')}
                                                                    </div>
                                                                </Link>
                                                                <Link href={'/page/careers'}>
                                                                    <div
                                                                        className={'mx-4 mt-1 text-base hover:text-green-600 pointer h-8'}>
                                                                        {t('profile.careers')}
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>

                                                        <div className={'flex flex-col mt-10 w-1/3 px-4'}>
                                                            <ColumnComponent className={'mt-4 px-4 items-center'}>
                                                                <div
                                                                    className={'text-lg px-4 mTextBlue w-full'}>{t('profile.beWithUs')}</div>
                                                                <RowComponent
                                                                    className={"mt-2 items-center justify-around"}>
                                                                    <a
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                        href={APARAT_URL}>
                                                                        <Image
                                                                            fill={true}
                                                                            sizes={'100%'}
                                                                            alt={''}
                                                                            className={'mx-2 rounded-md pointer !w-8 !h-8 !relative'}
                                                                            src={APARAT_IMAGE}
                                                                        />
                                                                    </a>
                                                                    <a
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                        href={LINKEDIN_URL}>
                                                                        <Image
                                                                            fill={true}
                                                                            sizes={'100%'}
                                                                            alt={''}
                                                                            className={'mx-2 rounded-md pointer !w-8 !h-8 !relative'}
                                                                            src={LINKEDIN_IMAGE}
                                                                        />
                                                                    </a>
                                                                    <a
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                        href={INSTAGRAM_URL}>
                                                                        <Image
                                                                            fill={true}
                                                                            sizes={'100%'}
                                                                            alt={''}
                                                                            className={'mx-2 rounded-md pointer !w-10 !h-10 !relative'}
                                                                            src={INSTAGRAM_IMAGE}
                                                                        />
                                                                    </a>
                                                                </RowComponent>
                                                            </ColumnComponent>
                                                            <Stack spacing={1}
                                                                   display={(`${process.env.NEXT_PUBLIC_NODE_ENV}`.trim() === ENGLISH_APP) ? "none" : "flex"}
                                                                   direction={'row'} className={"w-full mt-4"}>
                                                                <img
                                                                    alt={''}
                                                                    className={'border-2 rounded-md h-auto w-1/3 aspect-[1/1] pointer object-contain'}
                                                                    src={REZI_IMAGE}
                                                                    onClick={() => clickREZI()}
                                                                />
                                                                <a
                                                                    referrerPolicy="origin"
                                                                    className={'border-2 rounded-md h-auto w-1/3 aspect-[1/1]  pointer'}
                                                                    target="_blank"
                                                                    href={ENAMAD_URL}
                                                                >
                                                                    <img
                                                                        referrerPolicy="origin"
                                                                        src={ENAMAD_IMAGE}
                                                                        alt=""
                                                                        className={'pointer object-contain'}
                                                                        id="DKeATPe3kI2L8as0Bo56"
                                                                    />
                                                                </a>
                                                                <a
                                                                    referrerPolicy="origin"
                                                                    className={'border-2 rounded-md h-auto w-1/3 aspect-[1/1]  pointer'}
                                                                    target="_blank"
                                                                    href={SENFI_URL}
                                                                >
                                                                    <img
                                                                        referrerPolicy="origin"
                                                                        src={SENFI_IMAGE}
                                                                        alt=""
                                                                        className={'pointer object-contain'}
                                                                        id="DKeATPe3kI2L8as0Bo56"
                                                                    />
                                                                </a>
                                                            </Stack>
                                                        </div>
                                                    </RowComponent>
                                                    <RowComponent
                                                        className={'mt-4 mBackgroundBlue py-4 px-4 totalCenter rounded-lg'}>
                                                        <Image fill={true} sizes={'100%'} alt={''} src={LOGO_WHITE_ICON}
                                                               className={'!w-16 !h-16 p-2 !relative'}/>
                                                        <div
                                                            className={'w-full px-4 text-2xl text-bold text-white'}>{t('profile.downloadFrom')}</div>
                                                        {(`${process.env.NEXT_PUBLIC_NODE_ENV}`.trim() == ENGLISH_APP) ? null :
                                                            <a
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                className={"mx-2 !w-1/4 rounded-md"}
                                                                href={CAFE_TOOLBOX_URL}>
                                                                <Image
                                                                    fill={true}
                                                                    sizes={'auto'}
                                                                    alt={''}
                                                                    className={'rounded-md pointer !relative'}
                                                                    src={CAFE_DOWNLOAD_IMAGE}
                                                                />
                                                            </a>}
                                                        <a
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className={"mx-2 !w-1/4  rounded-md"}
                                                            href={PLAY_TOOLBOX_URL}>
                                                            <Image
                                                                fill={true}
                                                                sizes={'auto'}
                                                                alt={''}
                                                                className={'rounded-md pointer !relative'}
                                                                src={PLAY_DOWNLOAD_IMAGE}
                                                            />
                                                        </a>
                                                        {(`${process.env.NEXT_PUBLIC_NODE_ENV}`.trim() == ENGLISH_APP) ? null :
                                                            <a
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                className={"mx-2 !w-1/4 rounded-md"}
                                                                href={MYKET_TOOLBOX_URL}>
                                                                <Image
                                                                    fill={true}
                                                                    sizes={'auto'}
                                                                    alt={''}
                                                                    className={'rounded-md pointer !relative'}
                                                                    src={MYKET_DOWNLOAD_IMAGE}
                                                                />
                                                            </a>}
                                                        <a
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className={"mx-2 !w-1/4 rounded-md"}
                                                            href={i18n.language == ENGLISH ? DIRECT_PLAY_TOOLBOX_URL : DIRECT_CAFE_TOOLBOX_URL}>
                                                            <Image
                                                                fill={true}
                                                                sizes={'auto'}
                                                                alt={''}
                                                                className={'rounded-md pointer !relative'}
                                                                src={(`${process.env.NEXT_PUBLIC_NODE_ENV}`.trim() == ENGLISH_APP) ? DIRECT_DOWNLOAD_EN_IMAGE : DIRECT_DOWNLOAD_IMAGE}
                                                            />
                                                        </a>
                                                    </RowComponent>
                                                    <div
                                                        className={"text-sm px-4 py-4 mTextLighter w-full text-center"}>{t('detail.toolboxRight')}</div>
                                                </ColumnComponent>
                                            </ContainerLayout>
                                        </ColumnComponent>
                                    </LoginContainer>
                                </ThemeProvider>
                            </StyleSheetManager>
                        </CacheProvider>
                    </Provider>
                </GoogleOAuthProvider>
            </main>
        </>
    );
}

export default MyApp;
