import i18n from 'i18next';
import translationAr from '../../assets/i18n/ar/translation.json';
import translationEn from '../../assets/i18n/en/translation.json';
import translationFa from '../../assets/i18n/fa/translation.json';
import {initReactI18next} from 'react-i18next';
import {ENGLISH_APP} from "../services/CONSTANTS";

export const resources = {
  en: {
    translation: translationEn
  },
  ar: {
    translation: translationAr
  },
  fa: {
    translation: translationFa
  }
};

i18n.use(initReactI18next).init({
  lng: (`${process.env.NEXT_PUBLIC_NODE_ENV}`.trim() === ENGLISH_APP)?'en':'fa',
  fallbackLng: (`${process.env.NEXT_PUBLIC_NODE_ENV}`.trim() === ENGLISH_APP)?'en':'fa',
  debug: false,
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  },
  resources
});
export default i18n;
