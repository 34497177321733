import {MenuBuilderDetailModel, MenuBuilderWidgetModel, SchemaDataModel} from '../../../../Main/main.model';
import {menuDb} from './menuDB';
import i18n from 'i18next';
import {Page} from './models/menudb.page';
import {Shortcut} from './models/menudb.shortcut';
import {Widget} from './models/menudb.widget';

export class MenuDatabaseHelper {
  public async addMenuItems(items: MenuBuilderDetailModel[]) {
    try {
      for (const item of items) {
        const pageId = await menuDb.page.put({
          id: item.id,
          lng: i18n.language,
          title: item.title,
          unique_name: item.unique_name,
          group: item.group,
          widgetIds: item.widgets.map((i) => i.id),
          subPageIds: item.sub_pages.map((i) => i.id)
        });
        for (const widget of item.widgets) {
          const widgetId = await menuDb.widget.put({
            id: widget.id,
            title: widget.title,
            unique_name: widget.unique_name,
            category: widget.category,
            shortcutIds: widget.shortcuts.map((i) => i.id),
            blueprint_unique_name: widget.blueprint_unique_name,
            show_more_page: widget.show_more_page ?? '',
            schema_data: widget.schema_data,
            shortcuts: []
          });
          for (const shortcut of widget.shortcuts) {
            const shId = await menuDb.shortcut.put({
              id: shortcut.id,
              titleFa: shortcut?.title?.fa ?? '',
              titleEn: shortcut?.title?.en ?? '',
              unique_name: shortcut.unique_name,
              cover_url: shortcut.cover_url ?? '',
              icon_url: shortcut.icon_url ?? '',
              is_enabled: shortcut.is_enabled ?? true,
              login_required: shortcut.login_required ?? false,
              tooltip: shortcut.tooltip ?? '',
              tooltip_color: shortcut.tooltip_color ?? '',
              icon_color: shortcut.icon_color ?? '',
              url: shortcut.url ?? ''
            });
          }
        }
      }
    } catch (error) {
      console.log(`Failed to add ${error}`);
    }
  }

  public async getMenuPage(group: string): Promise<Page[]> {
    return menuDb.page.where({ group: group, lng: i18n.language }).toArray();
  }

  public async getMenuPageWidgets(widgetIds: number[]): Promise<(Widget | undefined)[]> {
    return menuDb.widget.bulkGet(widgetIds);
  }

  public shortcutMapper(shortcuts: (Shortcut | undefined)[]) {
    return shortcuts.map((shortcut) => {
      return {
        id: shortcut?.id ?? 0,
        title: shortcut?.titleFa ? { fa: shortcut?.titleFa ?? '', en: shortcut?.titleEn ?? '' } : null,
        url: shortcut?.url ?? '',
        cover_url: shortcut?.cover_url ?? '',
        icon_url: shortcut?.icon_url ?? '',
        tooltip: shortcut?.tooltip ?? '',
        icon_color: shortcut?.icon_color ?? '',
        is_enabled: shortcut?.is_enabled ?? true,
        login_required: shortcut?.login_required ?? false,
        unique_name: shortcut?.unique_name ?? '',
        tooltip_color: shortcut?.tooltip_color ?? ''
      };
    });
  }

  public async getMenuItems(mainPage: MenuBuilderDetailModel, widgetIds: number[]): Promise<MenuBuilderWidgetModel[]> {
    return await Promise.all(
      (
        await menuDb.widget.bulkGet(widgetIds)
      ).map(async (widget) => {
        if (widget) {
          widget.shortcuts = await menuDb.shortcut.bulkGet(widget?.shortcutIds ?? []);
        }
        return widget;
      })
    ).then((widgets) => {
      return widgets.map((widget) => {
        const mainWidget: MenuBuilderWidgetModel = {
          id: widget?.id ?? 0,
          title: widget?.title ?? '',
          unique_name: widget?.unique_name ?? '',
          shortcuts: this.shortcutMapper(widget?.shortcuts ?? []),
          show_more_page: widget?.show_more_page ?? '',
          blueprint_unique_name: widget?.blueprint_unique_name ?? '',
          category: widget?.category ?? '',
          ads: [],
          schema_data: widget?.schema_data ?? ({} as SchemaDataModel)
        };
        return mainWidget;
      });
    });
  }
}
