import React, {FC} from 'react';
import {MenuBuilderShortcutModel} from '../../../../Main/main.model';
import {LOGIN_STATE_KEY, PERSIAN} from '../../../../../core/services/CONSTANTS';
import {useTranslation} from 'react-i18next';
import {getShortcutLinkParams} from './service/shortcut.service';
import {getIconColor} from './utils/shortcut.utils';
import {LOGIN_ACTION} from '../../../../profile/components/Login/redux/loginAction';
import {LOGIN_STATE} from '../../../../profile/components/Login/CONSTANTS';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../../../../core/redux/store';

import i18n from 'i18next';
import {ColumnComponent} from '../../../../../core/components/base/column.component';
import useLocalStorageState from '../../../../../core/utils/useLocalStorage';
import Image from 'next/image';
import {IMAGE_PLACEHOLDER_IMAGE} from '../CONSTANTS';
import {LinkComponent} from "../../../../../core/components/link.component";

interface ShortcutProps {
  shortcut: MenuBuilderShortcutModel;
  disableClick?: boolean;
}

const ShortcutComponent: FC<ShortcutProps> = (props) => {
  const { t } = useTranslation();
  let dispatch = useDispatch<AppDispatch>();
  const [isLogin, setLogin] = useLocalStorageState<boolean>(LOGIN_STATE_KEY, false);
  const [imageError, setImageError] = React.useState(false);

  function getShortcutTitle() {
    if (props.shortcut.title) {
      return i18n.language == PERSIAN ? props.shortcut.title.fa : props.shortcut.title.en;
    } else {
      return t(`shName.${props.shortcut.unique_name}`);
    }
  }

  function shortcutAction() {
    if (!(props.disableClick ?? false) && (props.shortcut?.is_enabled ?? true)) {
      if ((props.shortcut.login_required ?? false) && !isLogin) {
        return <ColumnComponent onClick={()=>
            dispatch({
              type: LOGIN_ACTION,
              openLoginPage: true,
              state: LOGIN_STATE
            })}>
          {shortcutComponentItem()}
        </ColumnComponent>
      } else {
        let title = getShortcutTitle();
        return <LinkComponent pathname={getShortcutLinkParams(props.shortcut, title, dispatch)?.pathname??""}
                              query={getShortcutLinkParams(props.shortcut, title, dispatch)?.query??{}}
                              isOpenWindow={getShortcutLinkParams(props.shortcut, title, dispatch)?.isOpenWindow??false}>
          {shortcutComponentItem()}
        </LinkComponent>
      }
    } else {
      return shortcutComponentItem()
    }
  }

  function shortcutComponentItem(){
    return <ColumnComponent
        className="totalCenter pointer mb-2"
        style={{ opacity: props.shortcut?.is_enabled ?? true ? '1' : '0.5' }}
    >
      <ColumnComponent className="totalCenter px-2.5 relative">
        {props.shortcut.icon_url?.includes('http') ? (
            <Image
                fill={true}
                alt=""
                sizes={'100%'}
                className="aspect-[1/1] shortcutIconImage mRounded-2 !relative"
                style={{ backgroundColor: getIconColor(props.shortcut.icon_color) }}
                onError={(event) => setImageError(true)}
                onEmptied={() => setImageError(true)}
                src={imageError ? IMAGE_PLACEHOLDER_IMAGE : props.shortcut.icon_url}
                placeholder={'empty'}
            />
        ) : (
            <Image
                alt=""
                fill={true}
                sizes={'100%'}
                style={{ backgroundColor: getIconColor(props.shortcut.icon_color) }}
                onError={(event) => setImageError(true)}
                className="aspect-[1/1]  shortcutIconImage flex flex-col  mRounded-2 !relative"
                src={imageError ? IMAGE_PLACEHOLDER_IMAGE : `/image/png_ic_${props.shortcut.unique_name}.png`}
                placeholder={'empty'}
            />
        )}
      </ColumnComponent>
      {props.shortcut.title ? (
          <div className="mText text-center text-xs max-h-4 mt-2 w-full truncate">
            {i18n.language == PERSIAN ? props.shortcut.title.fa : props.shortcut.title.en}
          </div>
      ) : (
          <div className="mText text-center text-xs max-h-4 mt-2 w-full truncate">
            {t(`shName.${props.shortcut.unique_name}`)}
          </div>
      )}
    </ColumnComponent>
  }

  return (
    <div className="w-full">
      {props.shortcut ?
          shortcutAction()
      : null}
    </div>
  );
};

export default React.memo(ShortcutComponent)
