import React, {FC, useEffect, useState} from 'react';
import {RowComponent} from '../../base/row.component';
import {BottomNavItems, BottomNavProps} from './bottomNav.models';
import {ColumnComponent} from '../../base/column.component';
import {useTranslation} from 'react-i18next';
import Image from 'next/image';

export const BottomNavigationCustom: FC<BottomNavProps> = (props) => {
  const { t } = useTranslation();
  const [navItemValue, setNavItemValue] = useState<string>(props.valueKey);

  function onPositionChange(item: BottomNavItems) {
    props.positionChange(item.valueKey);
  }

  useEffect(() => {
    setNavItemValue(props.valueKey);
  }, [props.valueKey]);

  return (
    <RowComponent className="totalCenter mBackgroundDark">
      {props.items.map((item, index) => (
        <ColumnComponent key={index} className={'totalCenter p-1 pointer'} onClick={() => onPositionChange(item)}>
          <Image
            fill={true}
            sizes={'100%'}
            className={`py-1.5 !relative ${
              navItemValue === item.valueKey ? 'bottomNavImageScale animation' : 'bottomNavImage'
            }`}
            alt={''}
            src={props.valueKey === item.valueKey ? item.activeImage : item.image}
          />
          <div
            className={`w-full text-center mb-3 ${
              navItemValue === item.valueKey ? 'mTextBlue text-sm' : 'mText text-xs'
            }`}
          >
            {t(item.text)}
          </div>
        </ColumnComponent>
      ))}
    </RowComponent>
  );
};
