import {AnyAction} from 'redux';
import {MenuBuilderWidgetModel} from '../main.model';
import {MENU_BUILDER_ACTION, WIDGET_ACTION} from './mainAction';
import {PAGE_HOME_KEY} from '../CONSTANTS';

export interface MenuBuilderState {
  loading: boolean;
  state: string;
}

const initialMenuBuilderState: MenuBuilderState = {
  loading: true,
  state: PAGE_HOME_KEY
};

export interface ShortcutListState {
  loading: boolean;
  items: MenuBuilderWidgetModel | object;
  hasSearch: boolean;
  error: string;
}

const initialShortcutListState: ShortcutListState = {
  loading: true,
  items: {},
  hasSearch: false,
  error: ''
};

export function menuBuilderReducer(state = initialMenuBuilderState, action: AnyAction) {
  switch (action.type) {
    case MENU_BUILDER_ACTION:
      return {
        loading: action.loading,
        state: action.state
      };
    default:
      return state;
  }
}

export function widgetReducer(state = initialShortcutListState, action: AnyAction) {
  switch (action.type) {
    case WIDGET_ACTION:
      return {
        loading: action.loading,
        items: action.items,
        error: action.error,
        hasSearch: action.hasSearch
      };
    default:
      return state;
  }
}
