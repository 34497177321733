export const CAMPAIGN_URL = 'p/loyalty/lottery/v2/';
export const CAMPAIGN_TOKEN_URL = 'r/loyalty/lottery/v2/';

export const CAMPAIGN_IN_PROGRESS = 'I';
export const CAMPAIGN_DRAWING = 'L';
export const CAMPAIGN_FINISHED = 'F';
export const CAMPAIGN_NOT_STARTED = 'S';

export const COIN_IMAGE = '/image/coin.png';

export const DAYS_MILLISECONDS = 86400000;
export const HOURS_MILLISECONDS = 3600000;
export const MINUTES_MILLISECONDS = 60000;
