import {AxiosRequestConfig, AxiosRequestHeaders} from 'axios';
import {getAppPackage} from './localStorage.service';
import i18n from 'i18next';

const { versionCode } = require('/package.json');

export const axiosRequestConfiguration: AxiosRequestConfig = {
  baseURL: process.env.NEXT_PUBLIC_API_ENDPOINT,
  responseType: 'json',
  headers: getApiHeaderObject(getAppPackage(), i18n.language)
};

export function getApiHeaderObject(pkg: string, lng: string): AxiosRequestHeaders {
  return {
    'App-Version': `${versionCode}`,
    'App-Package': pkg,
    'Content-Type': 'application/json',
    'Accept-Language': lng,
    apikey: process.env.NEXT_PUBLIC_API_KEY + ''
  } as any as AxiosRequestHeaders;
}

export function getApiHeaderObjectWithToken(pkg: string, lng: string, token: string): AxiosRequestHeaders {
  return {
    'App-Version': `${versionCode}`,
    'App-Package': pkg,
    'Content-Type': 'application/json',
    'Accept-Language': lng,
    apikey: process.env.NEXT_PUBLIC_API_KEY + '',
    Authorization: token
  } as any as AxiosRequestHeaders;
}

export function getApiHeaderObjectWithTokenObject(pkg: string, lng: string, token: string): any {
  return {
    'App-Version': `${versionCode}`,
    'App-Package': pkg,
    'Content-Type': 'application/json',
    'Accept-Language': lng,
    apikey: process.env.NEXT_PUBLIC_API_KEY + '',
    Authorization: token
  } as any as AxiosRequestHeaders;
}
